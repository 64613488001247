.map {
    &__list-filter {
        list-style: none;
        padding-left: 0;
        float: left;
        margin: 0;
        height: 36px;
        line-height: 36px;

        label{
            font-size: 16px;
        }

        li {
            float: left;
            margin-left: 15px;
        }

        li:first-child {
            margin-left: 0;
        }

        li > img{
            max-height: 40px;
        }

        li > span {
            font-size: 16px;
            margin: 0px 5px;
        }
    }

    &__controls {
        margin: 30px 0 15px;

        .btn {
        }

        .btn > i {
            margin-right: 10px;
        }
    }

    &__finder {
        position: relative;
        float: right;

        .fa {
            position: absolute;
            top: 10px;
            right: 12px;
        }
    }

    &__search {
        width: 250px;
        padding: 5px 10px;
        padding-right: 25px;
        outline: 0;

        @media (max-width: 767px) {
            width: 150px;
        }
    }

    &__info {
        min-width: 170px;

        a,
        span {
            font-size: 14px;
            position: relative;
            display: inline-block;
            padding-left: 20px;
            width: 100%;
            color: #333;

            &::before {
                font-family: 'fontAwesome';
                position: absolute;
                top: 0;
                left: 0;
                font-size: 15px;
                color: $primary-color;
            }
        }

        a {
            color: $primary-color;
        }

        img {
            width: 130px;
            margin-top: 3px;
            margin-bottom: 15px;
        }

        .business-logo {
            &:before {
                content: '';
                width: 13px;
                height: 12px;
                background-image: url('/website/static/images/hiteco/maps-icon.png');
                background-size: 100%;
                background-position: center center;
                top: 5px;
            }
        }
    }
}
@include flag-icon(algeria);
@include flag-icon(argentina);
@include flag-icon(armenia);
@include flag-icon(australia);
@include flag-icon(austria);
@include flag-icon(azerbaijan);
@include flag-icon(bangladesh);
@include flag-icon(belgium);
@include flag-icon(brazil);
@include flag-icon(bulgaria);
@include flag-icon(cameroon);
@include flag-icon(canada);
@include flag-icon(chile);
@include flag-icon(china);
@include flag-icon(costa-rica);
@include flag-icon(cote-ivoire);
@include flag-icon(cuba);
@include flag-icon(cyprus);
@include flag-icon(czech-republic);
@include flag-icon(denmark);
@include flag-icon(egypt);
@include flag-icon(england);
@include flag-icon(estonia);
@include flag-icon(european-union);
@include flag-icon(finland);
@include flag-icon(france);
@include flag-icon(germany);
@include flag-icon(ghana);
@include flag-icon(greece);
@include flag-icon(guinea);
@include flag-icon(hungary);
@include flag-icon(iceland);
@include flag-icon(india);
@include flag-icon(iraq);
@include flag-icon(ireland);
@include flag-icon(israel);
@include flag-icon(italy);
@include flag-icon(jamaica);
@include flag-icon(japan);
@include flag-icon(latvia);
@include flag-icon(lithuania);
@include flag-icon(luxemburg);
@include flag-icon(madagascar);
@include flag-icon(mexico);
@include flag-icon(monaco);
@include flag-icon(netherlands);
@include flag-icon(new-zealand);
@include flag-icon(niger);
@include flag-icon(nigeria);
@include flag-icon(north-korea);
@include flag-icon(norway);
@include flag-icon(pakistan);
@include flag-icon(peru);
@include flag-icon(poland);
@include flag-icon(qatar);
@include flag-icon(romania);
@include flag-icon(russia);
@include flag-icon(saudi-arabia);
@include flag-icon(scotland);
@include flag-icon(slovakia);
@include flag-icon(slovenia);
@include flag-icon(somalia);
@include flag-icon(south-africa);
@include flag-icon(south-korea);
@include flag-icon(spain);
@include flag-icon(sweden);
@include flag-icon(switzerland);
@include flag-icon(syria);
@include flag-icon(thailand);
@include flag-icon(tunisia);
@include flag-icon(turkey);
@include flag-icon(ukraine);
@include flag-icon(united-arab-emirates);
@include flag-icon(united-kingdom);
@include flag-icon(united-states);
@include flag-icon(vietnam);
@include flag-icon(wales);
@include flag-icon(yemen);
@include flag-icon(world);

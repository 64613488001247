
.h1,.h2,.h3,.h4,.h5,.h6,
h1,h2,h3,h4,h5,h6 {
    text-transform: uppercase;
    font-weight: 700;
    display: block;
}

.h1,h1 {
    @media (max-width: 992px){
        font-size: 40px;
    }
}
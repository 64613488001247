
.title {
    &--icon {
        padding-left: 50px;
        position: relative;
        margin: 0;

        .fa {
            position: absolute;
            top: 50%;
            left: 0;
            font-size: 30px;
            margin-top: -15px;
            color: $secondary-color;
        }
    }
}

.no-margin {
    margin: 0;
}
.no-padding {
    padding: 0;
}
.fading {
    overflow: hidden;
    position: relative;
    height: 5.4em; /* exactly three lines */

    &:after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 1.2em;
        background: linear-gradient(to right, rgba(255,255,255,0), $white 95%);
        //visibility: hidden;

        .spot-image--colored & {
            background: linear-gradient(to right, rgba(255,255,255,0), $grey-light 95%);
        }
    }

}
.fading-2-line {
    @extend .fading;
    height: 2.4em;
}
.fading-3-line {
    @extend .fading;
    height: 3.6em;
}
.fading-4-line {
    @extend .fading;
    height: 4.4em;
}
.fading-5-line {
    @extend .fading;
    height: 5.6em;
}

.fading-2-line,
.fading-3-line,
.fading-4-line {
    .s-prodlist__item & {
        height: auto;
        position: static;

        &:after {
            display: none;
        }
    }
}

.full-link {
    @include full;
}

.close-icon {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    overflow: hidden;

    &::before, &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -1px;
        background: #000;
    }

    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
    &.big {
        transform: scale(3);
    }
    &.hairline {
        &::before, &::after {
            height: 1px;
        }
    }
    &.thick {
        &::before, &::after {
            height: 4px;
            margin-top: -2px;
        }
    }
    &.black {
        &::before, &::after {
            height: 8px;
            margin-top: -4px;
        }
    }
    &.heavy {
        &::before, &::after {
            height: 12px;
            margin-top: -6px;
        }
    }
    &.pointy {
        &::Before, &::after {
            width: 200%;
            left: -50%;
        }
    }
    &.rounded {
        &::before, &::after {
            border-radius: 5px;
        }
    }
    &.blades {
        &::before, &::after {
            border-radius: 5px 0;
        }
    }
    &.warp {
        &::before, &::after {
            border-radius: 120% 0;
        }
    }
    &.fat {
        &::before, &::after {
            border-radius: 100%;
        }
    }
}

// Header
.header__top-panel {
    //padding: 5px 0;
    background-color: $grey-bg;
}

.menuscm .showhide em { 
    margin: 3.5px 17px 0;
}

// Content

.main-content .btn.btn-more:after {
    display: none!important;
}

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
    margin-right: -40px;
}

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
    margin-left: -40px;
}

// Footer
.footer-holder {
    background-color: $default;
    color: #9b9b9b;

    li a {
        color: #9b9b9b;

        &:hover {
            color: #9b9b9b;
        }
    }
}
.footer-nav {
    font-size: inherit;

    li a {
        color: #9b9b9b;
        font-size: 14px;

        &:hover {
            color: #9b9b9b;
            text-decoration: underline;
        }
    }
}

#footer {
    max-width: inherit;
    margin: auto;
    padding: 30px 0;

    .logo {
        display: none;
    }
    .top-panel {
        color: #9b9b9b;
        padding: auto;
    }
    .copy {
        font-size: 14px;
        line-height: 1.42;
    }
}

.headbg--blue {
    padding: 1rem 2rem;
    color: white;
    background-color: $bluscm;
    border-radius: 5px;

    &.with-bg {
        padding-top: 30px;
        padding-bottom: 30px;
        background-image: url(../../images/hiteco/texture_head.png);
        background-position: top right;
        background-repeat: no-repeat;
    }
}
.headbg--default {
    padding: 1rem 2rem;
    color: white;
    background-color: #00A9E0;
    border-radius: 5px;
}

// Custom margin class
$m-sizes: 30px, 60px, 90px;
@each $m-size in $m-sizes {
  .mb-#{$m-size} {
    margin-bottom: #{$m-size};
  }
  .mt-#{$m-size} {
    margin-top: #{$m-size};
  }
  .my-#{$m-size} {
    margin-bottom: #{$m-size};
    margin-top: #{$m-size};
  }
}

.flex-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 30px;

    @media (min-width: 767px){
        flex-direction: row;
        justify-content: flex-start;
    }

    &__item {
        display: flex;
        flex-direction: row;
        margin-right: 30px;

        h4 {
            margin-top: 0;
        }

        > div {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}



$mq-breakpoints: (
  // Base device classes
        mobile:      320px,
        tablet:      640px,
        desktop:    1024px,
        widescreen: 1200px,
        tvscreen:   1920px,

  // Tweakpoints
        mobileLandscape: 480px
);

// Show active breakpoint in top right corner of viewport
// $mq-show-breakpoints: (mobile, mobileLandscape, tablet, desktop, widescreen, tvscreen);

//Fonts
$montserrat-font: 'Montserrat', sans-serif;

//Colors
$default: #333;

$grey-light: #f5f5f5;
$grey-hover: #f5f5f5;
$grey-border: #ccc;
$grey-table: #fcfcfc;
$bluscm: #326295;
$grey-default: #e5e5e5;

// example: @include single-transition(background, 1s, ease-in-out, 0s);
@mixin single-transition($property, $duration, $timing-function, $delay) {
  -webkit-transition: $property $duration $timing-function $delay;
  -moz-transition: $property $duration $timing-function $delay;
  transition: $property $duration $timing-function $delay;
}

// example: @include double-transition(background, 1s, ease-in-out, 0s, opacity, .1s, ease-in-out, 0s);

@mixin double-transition($property1, $duration1, $timing-function1, $delay1, $property2, $duration2, $timing-function2, $delay2) {
  -webkit-transition: $property1 $duration1 $timing-function1 $delay1, $property2 $duration2 $timing-function2 $delay2;
  -moz-transition: $property1 $duration1 $timing-function1 $delay1, $property2 $duration2 $timing-function2 $delay2;
  transition: $property1 $duration1 $timing-function1 $delay1, $property2 $duration2 $timing-function2 $delay2;
}

$primary-color: #326295;
$secondary-color: #ccc;

.inner--new .section-visual {
    min-height: 350px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 767px){
        min-height: inherit;
        padding: 50px 0;
    }

    h1 {
        margin-bottom: 15px;
    }

    .text-hold {
        position: relative;
        bottom: auto;
        left: auto;

        .date .fa {
            margin-right: 10px;
        }
    }

    &:before {
        width: 100%;
        height: 100%;
        min-height: inherit;
    }
}
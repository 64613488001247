
.btn {
    display: inline-block;
    padding: 0 30px;
    font-family: $headings-font-family;
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
    color: $default;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center!important;

    transition: border-color .25s ease-in-out 0s;

    &:focus,
    &:active,
    &:hover {
        text-decoration: none!important;
        outline: 0;
    }

    &--filter {
        color: $default;
        background-color: $grey-light;
        height: 40px;
        line-height: 40px;
        position: relative;
        font-size: 14px;
        padding: 0 15px;

        transition: all .25s ease-in-out;


        &-blue{
            background-color: $primary-color;
            color: white;
        }

        &.is-checked {
            color: white;
            background-color: $primary-color;
        }

        &:hover {
            background-color: $secondary-color;
        }
    }

    &--rounded,
    &--rounded-tr {
        border-radius: 145px!important;
        text-align: center;
        border: 2px solid $primary-color;
        padding: 13px 35px;

        .site-footer & {
            color: $grey-foot;
            border-color: $grey-border;

            &:hover {
                border-color: $grey-foot;
            }
        }
    }

    &--rounded {
        background-color: $primary-color;
        color: white!important;
        transition: all .25s ease-in-out 0s;

        &:active,
        &:hover {
            color: $primary-color!important;
            background-color: transparent;
        }
    }

    &--rounded-tr {
        color: $secondary-color;
        border-color: $secondary-color;
        background-color: transparent;

        &:hover {
            color: $primary-color!important;
            border-color: $primary-color;
        }
    }

    &--link {
        font-size: 12px;
        font-family: $headings-font-family;
        line-height: 15px;
        color: $primary-color;
        position: relative;
        padding: 0;
        overflow: hidden;
        padding-bottom: 5px;
        text-transform: uppercase;
        overflow: hidden;
        display: inline-block;

        &:hover {
            color: $primary-color!important;
            text-decoration: none!important;
        }
        &:after,
        &:before {
            content: '';
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $primary-color;
            transition: transform 0.25s linear 0s;

            .editmode & {
                display: none;
            }
        }
        &:before {
            opacity: .8;
            transform: translateX(0);
        }
        &:after {
            opacity: 1;
            transform: translateX(-100%);
        }
        &:hover:after {
            transform: translateX(0);
        }
    }

    &--link-wh {
        @extend .btn--link;
        color: white!important;

        &:hover {
            color: white!important;
        }
        &:before,
        &:after {
            background-color: white;
        }
    }

    &--search-close{
        z-index: 20;
        position: unset !important;
    }

    &--win-close {
        opacity: 1;

        transform: scale3d(1, 1, 1);
        transition: opacity 0.5s linear 0s, transform 0.5s linear 0s;
    }

    // Vecchi bottoni
    &--default {
        @extend .btn--rounded-tr;
    }

    &--primary {
        @extend .btn--rounded;

        &.white {
            color: white;
            border-color: white;
            background-color: transparent;

            &:hover {
                color: $primary-color;
                background-color: white;
            }
        }
    }

    &--nopadd {
        @extend .btn--primary;
    }

    &-primary {
        @extend .btn--primary;
        border: 2px solid $primary-color!important;

        &:hover {
            border: 2px solid $primary-color!important;
            color: $primary-color!important;
            background-color: transparent!important;
        }
    }

}
/*
    Created on : 2-lug-2015, 16.01.42
    Author     : Lorenzo Nicoletti <lorenzo.nicoletti@extera.com>
*/

figure {
    display: table;
    table-layout: fixed;
    margin-bottom: 1em;

    //Explorer
    /*@media screen { 
        @media (min-width: 0px) {
            width: 100%;
        } 
    }*/

    //Firefox
    @-moz-document url-prefix() {
        width: 100%;
    }

    img {
        margin-bottom: .4em;
        border: 0;
    }
    figcaption {
        display: table-caption;
        caption-side: bottom;
        font-style: italic;
        font-size: 13px;
    }
}

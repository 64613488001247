/**

N.B - NON USATELO PIU'

**/


/**
 * Cookie
 */
html body #pc-cookie-notice #pc-button button {
  background-color: darkgrey
}

/**
 * Image Hotspot e Marker
 */
.hotspot-and-marker {
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .image-hotspot {
    position: absolute;
    background: rgba(red($base-link-color), green($base-link-color), blue($base-link-color), 0.2);
    border: 1px solid $base-link-color;

    &:hover {
      background: rgba(red($link-hover-color), green($link-hover-color), blue($link-hover-color), 0.2);
      border: 1px solid $link-hover-color;
    }
  }

  .image-marker {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #2aabeb;
    &:hover {
      background: lighten(#2aabeb, 15%);
    }
  }
}

@font-face {
  font-family: 'scm';
  src: url('../fonts/scm-logo-webfont.eot');
  src: url('../fonts/scm-logo-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/scm-logo-webfont.woff2') format('woff2'),
  url('../fonts/scm-logo-webfont.woff') format('woff'),
  url('../fonts/scm-logo-webfont.ttf') format('truetype'),
  url('../fonts/scm-logo-webfont.svg#scm-logoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.grouplogo {
  font-weight: bold;

  &:before {
    font-family: 'scm';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\0061';
  }
}

.grouplogo-blue {
  color: $primary-color; /*#013c76 #003c76*/
}

.grouplogo-red {
  color: #ee3124;
}

a.brand-placeholder {
  text-decoration: none !important;
  &:hover {
    text-decoration: none;
  }
  img.grouplogo-image {
    vertical-align: middle;
  }
}

.area-gallery-carousel {
  margin-bottom: $line-height-computed;
}

.full-redirect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

#content {
  p {
    margin: 0 0 15px;
  }
}

.nophoto {
  display: inline-block;
  font-size: 90px;
  margin-top: -1%;
  color: #ddd;
  font-family: 'scm';
  font-weight: normal;
  &:before {
    content: "\0061";
  }
  vertical-align: middle;
}

/* Product */
.section {
  h1, h2 {
    font-weight: 200;
    color: $base-link-color;
  }

  h1 {
    margin-top: 0;
  }
}

/* Form di contatto */
#request-info {
  h2, h3 {
    margin-top: 0;
    color: $base-link-color;
  }
}


.field-with-description{
  .hint{
    font-size: 80%;
  }
}

.zend_form {
  float: left;
  width: 100%;
  margin-bottom: 35px;

  /*dt,dd{
      width: 33.333%;
      float: left;
  }

  dd{
      clear: left;
  }*/
}

/* Pimcore - Area Product */
.pimcore_area_product {
  width: 100%;
  padding: 10px;
  margin-bottom: 30px;

  .thumbnail {
    padding: 0;
  }

  &:hover {
    background-color: #f0f0f0;
  }

  &.grayBackground {
    background-color: #f5f5f5;
    &:hover {
      background-color: #eaeaea;
    }
  }
}

/* Class Image */
.mezzobusto {
  border-bottom: 1px solid #eee;
}

.icona-1 {
  margin: 10px 20px;
  @include opacity(.3);
}

/* Multiselect */
.multiselect-container.dropdown-menu {
  display: block;
  position: static;
  float: none;
  @include box-shadow(none);

  .has-error & {
    border-color: $state-danger-text;
    label {
      color: $input-color;
    }
  }
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.gallery.carousel {
  border: 1px solid #ccc;

  .item {
    height: 600px;
    text-align: center;

    @media (max-width: 992px) {
      height: 400px;
    }

    @media (max-width: 767px) {
      height: 300px;
    }

    @media (max-width: 480px) {
      height: 200px;
    }

    &:hover {
      background: #fff;
    }
  }

  .carousel-control {
    background: none !important;
    text-shadow: 0 0 1px 0 rgba(255, 255, 255, 1);
  }
}

.pimcore_area_Extera3Columns {
  .gallery.carousel {
    .item {
      height: 250px;
    }
  }
}

/* News Part */
.c-news {
  .date {
    text-transform: uppercase;
    display: block;
    margin-bottom: 1em;
    .fa-calendar {
      display: none;
    }
  }
  .media-body {
    .date {
      display: inline;
      margin-bottom: inherit;
    }
  }

  .media-heading {
    margin-bottom: 10px;
  }

  .news-thumbnail {
    img {
      margin-top: 7px;
    }
  }
}

/* Product - Part */
.c-product {
  .table {
    background-color: $grey-table;
    color: $default;
  }
}

.elenco-prodotti {
  .line {
    margin-bottom: 20px;
  }

  .list-cont-col {
    ul {
      list-style: none;
      padding: 0;
    }

    a {
      color: $default;
      &:hover {
        color: $default;
        text-decoration: underline;
      }
    }

    h3 {
      text-transform: inherit;

      a {
        color: $blue;
        &.ajax-popup {
          font-size: 80%;
          text-decoration: none;
          color: #999;
          u {
            display: none;
            text-decoration: none;
          }
          i {
            display: inherit
          }
          &:hover {
            text-decoration: none;
            u {
              display: inherit;
              text-decoration: none;
            }
            i {
              display: none
            }
          }
        }
      }
    }
  }
}

.obj-event-date {
  margin-right: 10px;
}

.pimcore_area_Extera3Columns {
  padding: 20px 0;
}

.pimcore_area_content {
  .pimcore_area_wysiwyg {
    //padding-top: 10px;
  }
}

/*
    Spazio prima dei wysiwyg che sono preceduti da un video
*/
.pimcore_area_video + .pimcore_area_wysiwyg {
  margin-top: 20px;
}

/*
    Fix: linea nera dei video
    l'iframe che bootstrap imposta alto 100% può diventare renderizzato a 312.750px quella frazione mostra una (o due, anche sotto) riga che è il background nero ospitato da YT
*/
html body .embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56%;
}

@media (max-width: 767px) {

  .top-panel .inf > ul {
    padding-top: 0;

    /*i {
      display: none;
  }*/
  }

  .box-social {
    .inf .dropdown {
      padding-right: 0;
    }
  }

  .nav-bar {
    padding: 0!important;
  }

  .searchform {
    position: static !important;
    z-index: 1 !important;
    opacity: 1 !important;

    input[type=text] {
      padding: 0 !important;
    }
  }

  .carousel-inner {
    .item {
      margin-bottom: 0;
    }
  }

  .carousel-indicators {
    display: none;
  }
}

.pimcore-pdfViewer {
  border: 1px solid #ccc;
}

.ajax-popup-content {
  max-width: 980px;
  margin: 2em auto;
  padding: 0px;
  background-color: white;

  &.category-brand-content {
    h1 {
      text-align: center;
      margin-top: 0;
    }
    h2 {
      margin-bottom: 15px;
    }
    hr {
      border: 0;
      height: 1px;
      background-image: linear-gradient(to right, rgba(204, 204, 204, 0), rgba(204, 204, 204, 0.75), rgba(204, 204, 204, 0));
    }
    .category-brand-col {
      /*padding: 0 15px 15px 0;*/
      margin-bottom: 20px;
      .category-brand-item {
        h3 {
          margin: 0 0 10px 0;
        }
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

/*
 modali
*/

.modal-heading {
  padding-top: 30px;
  padding-bottom: 40px;
}

.modal-left-part {
  .left-title-prod {
    text-align: right;
    font-size: 18px;
    margin-top: 0;
  }
}

.modal-right-part {
  margin-bottom: 30px;
  border-left: 2px solid #ccc;

  ul {
    a {
      color: #333;
    }
    a:hover {
      color: #333;
    }
  }
}

/*
 Bande sottotitoli
*/

.subtitle-highlight {
  padding: 10px 20px;
  color: $white!important;
  background-color: $blue;

  &.subtitle-invert {
    color: $blue!important;
    background-color: $grigio-chiaro;
  }

}

.box-social{
    float: right;
    overflow: hidden;

    span{
        float: left;
        padding: 2px 12px 0 0;
    }

    ul {
        @extend %listreset;
        overflow:hidden;
        float:left;

        li {
            float: left;
            padding: 0 0 0 9px;
        }

        a {
            display: inline-block;
            transition: opacity 0.3s ease;

            .header & {
                color: $default;
                font-size:16px;
            }

            &:hover{
                text-decoration: none;
                @include opacity(0.6);
            }
        }
    }
}

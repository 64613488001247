.card {
    //min-height: 200px;
    padding: 15px;
    background-color: #f0f0f0;
    border-radius: 5px;
    //border: 1px solid #ccc;
    position: relative;

    &.ratio-1-1 {
        aspect-ratio: 1 / 1;
    }

    &__flag {
        position: absolute;
        width: 100%;
        height: 15px;
        background-color: white;
        top: 0;
        left: 0;
        border-top: 1px solid #f0f0f0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        overflow: hidden;

        &:before,
        &:after {
            content: '';
            width: 33.333%;
            height: 15px;
            position: absolute;
            top: 0;
            z-index: 10;
        }

        &:before {
            background-color: green;
            left: 0;
        }
        
        &:after {
            background-color: red;
            right: 0;
        }
    }

    > h3 {
        font-size: 20px;
    }

    &--business {
        padding: 15px;
        height: 100%;
        
        @media (min-width: 992px){
            padding: 30px;
        }
    }

    &__tipologia{
        color: $base-link-color; 
        margin-bottom: 10px;
        vertical-align: middle;
        display: flex;
        align-items: center;

        img,
        svg{
            width: 24px;
            margin-right: 1rem;
            fill: $base-link-color; 
            transition: all 0.3s;

            &:nth-child(2) {
                margin-left: 1rem;
            }
        }
    }

    &__logo {
        display: inline-block;
        margin-bottom: 20px;
    }

    &__info {
        padding: 0 5px;

        .mb-10 {
            margin-bottom: 10px;
        }
    }

    &__business {
        font-family: $headings-font-family;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }

    &:hover {
        border-color: #b0b0b0;

        .card__tipologia svg{
            transform: scale(1.1);
        }
    }
}

// New custom card
.card {
    &--grey,
    &--blue,
    &--blue-light {
        padding: 15px;

        @media (min-width: 992px){
            padding: 30px;
        }

        h4 {
            font-size: 18px;
            margin-top: 0;
        }
    }
    &--blue {
        background-color: $bluscm;
        color: white;
    }
    &--blue-light {
        background-color: #00A9E0;
        color: white;
    }

    &__icon {
        max-width: 100%;
        width: auto;
        height: 90px;
        margin-bottom: 20px;
    }
    &__logo {
        max-width: 260px;
        margin-bottom: 15px;
        margin-left: -15px;
        margin-top: -15px;
    }

    &--bg {
        background-color: #263D76;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        color: white;
        padding: 20px 30px;
        min-height: 250px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        position: relative;
        overflow: hidden;

        > div {
            position: relative;
            z-index: 10;
        }

        &:after {
            content: '';
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, rgba(38,61,118,.75) 60%,transparent );            
        }

        @media (min-width: 992px){
            padding: 40px 60px;
        }
    }
}

.pimcore_area_business-card {
    margin-top: 30px;

    .row {
        display: flex;
        flex-flow: row wrap;

        @media (max-width: 767px) {
            flex-flow: column wrap;        
        }

        > div {
            margin-bottom: 30px;
        }
    }
}

#iubenda-cs-banner{
    /* ------ IMPORTANT NOTICE: don't change these values as
            they may prevent the banner from properly showing. Some of these lines,
            moreover (like z-index) will overwrite the configuration initialized
            by the script you paste in your pages. To show the banner at the bottom
            you can switch to "bottom: 0px !important;". Don't forget to put "slideDown"
            to "false".
     */
    bottom: 0px !important;
    left: 0px !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 99999998 !important;
    background-color: black;
    /* ----------------- */
}

/*  CONTENT CLASS */
.iubenda-cs-content{
    display:block;
    margin:0 auto;
    padding: 20px;
    width:auto;
    font-size: 14px;
    background: black;
    color: white;
    background-color: $bluscm;
}
.iubenda-banner-content{

}

.iubenda-cs-rationale{
    max-width: 900px; position:relative; margin: 0 auto;
}

/*   STYLE FOR TEXT INTERNAL BANNER */
.iubenda-banner-content > p {
    //font-family: Helvetica,Arial,FreeSans,sans-serif;
    line-height: 1.5;
}

/*  CLOSE BUTTON. NOTICE: IMAGE FOR BUTTON NOT IMPLEMENTED YET */
.iubenda-cs-close-btn{
    margin:0;
    color: white;
    text-decoration:none;
    font-size:14px;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
}

/*  STYLE FOR TEXT LINK TO PRIVACY POLICY */
.iubenda-cs-cookie-policy-lnk{
    text-decoration: underline;
    color: white;
    font-size: 14px;
    font-weight: 900;
}

.fair {
    &__item {
        //height: 180px;
        height: 150px;

        @media (max-width: 767px){
            height: auto;
            margin-bottom: 40px;
        }

        .media {
            padding: 12px;
            -webkit-transition: background .25s ease-in-out;
            -moz-transition: background .25s ease-in-out;
            -ms-transition: background .25s ease-in-out;
            -o-transition: background .25s ease-in-out;
            transition: background .25s ease-in-out;

            &:hover {
                background-color: $grey-light;
            }
        }
    }
    &__title-m {
        color: $bluscm;
        margin: 0 0 20px;
        font-weight: 200;
        text-transform: capitalize;
        line-height: 1.1;
        display: block;
        background-color: $grey-light;
        border: 10px solid $grey-light;
        //font-family: 'HelveticaNeueThin', Helvetica, Arial, sans-serif;
        font-family: "Helvetica", Arial, sans-serif;

        .fair__month--current & {
            background-color: $bluscm;
            border-color: $bluscm;
            color: white;
        }
    }
    &__title-f {
        margin: 0 0 7px!important;
        font-size: 18px!important;
        color: black!important;
        //font-family: 'HelveticaNeue', Helvetica, Arial, sans-serif;
        font-family: "Helvetica", Arial, sans-serif;
        font-weight: bold;
    }
    &__month {

    }
    &__text {
        .fa {
            margin-right: 5px;
            width: 20px;
            text-align: center;
        }
    }
    &__image {
        img {
            width: auto;
            height: 100%;
        }

        &--border {
            text-align: center;
            display: block;
            width: 85px;
            height: 85px;
            padding: 5px;
            background-color: white;
            border: 1px solid $grey-border;
        }
    }

    &__location {
        position: relative;
        padding-left: 25px;

        .fa {
            position: absolute;
            top: 3px;
            left: 0;
            margin-right: 0;
        }
    }
    &__stand {
        padding-left: 25px;
        font-weight: bold;
    }
    &__date,
    &__location,
    &__stand {
        font-size: 12px;
        line-height: 1.3;
        color: $default;
    }

    &__link {
        cursor: pointer;
    }
}

// New css
.filters {
    margin-top: 5px;
    margin-bottom: 40px;

    &--tab{
        margin-bottom: 0;
    }

    @media (max-width: 1200px){
        height: auto;
        line-height: inherit;
    }

    label {
        float: left;
        font-weight: 400;
        margin-bottom: 10px;
        margin-right: 13px;
        height: 40px;
        line-height: 40px;
    }

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        //margin-left: 13px;

        @media (max-width: 1200px){
            margin-top: 10px;
            clear: left;
        }
    }

    &__item {
        list-style: none;
        float: left;
        margin-right: 13px;

        &:last-child {
            margin-right: 0;
        }

        @media (min-width: 768px){
            margin-bottom: 13px;
        }

        @media (max-width: 767px) {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }

    .btn {
        cursor: pointer;
    }
}

.box-list {
    .grid-item {
        margin-bottom: 30px;
    }

    &__spot {
        border-radius: 5px;
        height: auto;

        @media (min-width: 767px){
            min-height: 420px;            
        }
        
        &:hover {
            .btn:after {
                transform: translateX(0);
            }
        }
    }
}

@mixin flag-icon-background {
  background-size: 13%;
  background-position: left;
  background-repeat: no-repeat;
}

.flag-icon {
  @include flag-icon-background();
  position: relative;
  display: inline-block;
  width: (4 / 3) * 1em;
  line-height: 1em;

  &:before {
    content: '\00a0';
  }

  &.flag-icon-squared {
    width: 1em;
  }

  &.world {
    background-size: 20%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: -5px;
    background-position: 3px center;
  }

}

@mixin flag-icon($country) {
  .flag-icon.#{$country} {
    background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/#{$country}.svg);
  }
}

#label-select-country.flag-icon.world {
  //background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/world_white.svg);
  background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/world.svg);

  /*.open & {
    background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/world.svg);
  }*/
}

.nav-holder {
    width: 100%;
    @extend %clearfix;
    background: white;

    @media (max-width: 767px) {
        .sticky & {
            position: static;
            border-bottom: 1px solid $grey-light;
        }
    }

    .navbar-nav {
        font-size: 15px;
        line-height: 17px;
        text-transform:uppercase;
        font-weight:bold;
        padding:5px 0 0;
        margin:0;
        background: white;
        position:relative;
        z-index:50;

        li {
            float:left;
            margin:0 0 0 34px;

            a{
                color: $default;
                padding:0;

                &:hover{
                    text-decoration:none;
                    color:$base-link-color;
                }
            }
            &:hover{
                a{
                    color:$base-link-color;
                    &:hover{
                        color:$base-link-color;
                    }
                    &:focus{
                        color:$base-link-color;
                        text-decoration:none;
                    }
                }
            }
        }
        li.search-button{
            .dropdown-menu{
                left: auto !important;
                // right: 0 !important;
                width: auto !important;
                margin-left: -15px;
                top: -16px;

                @include breakpoint($mobile){
                    padding-left: 0 !important;
                }
            }
            > a{
                position: relative;
                z-index: 99999999;
                font-size: 18px !important;
                line-height: 20px !important;
            }
        }
        .dropdown-menu{
            width:100%;
            left: 0;
            top: 130%;
            padding:0 9999px 25px;
            margin:0 -9999px;
            box-shadow: none;
            min-width: inherit;
            z-index: 50;
            border:none;
            overflow:hidden;
            ul{
                padding:6px 6px 0 0;
                margin:20px 0 0;
                @extend %clearfix;
                @extend %listreset;
                width:310px;
                font-size:13px;
                line-height:15px;
                text-transform:none;
                border-right:1px solid #ccc;
                position:relative;
                li{
                    float:none;
                    margin:0 0 13px;
                    width:100%;
                    display:block;
                    .logos{
                        @extend %listreset;
                        border-right:none;
                        margin:0 0 20px;
                        li{
                            float:left;
                            display:inline-block;
                            padding:0 9px 0 0;
                            width: auto;
                            margin:0;
                        }
                        img{
                            transition: opacity 0.3s ease;
                            &:hover{
                                @include opacity(0.7);
                            }
                        }
                    }
                }
                a{
                    color:$default;
                    display:inline-block;
                    i{
                        padding:0 0 0 5px;
                        vertical-align:top;
                        font-weight:bold;
                        opacity: 0;
                        transition: opacity 0.3s ease;
                        font-size:12px;
                        line-height:14px;
                    }
                    &:hover{
                        i{
                            opacity: 1;
                            color:$base-link-color;
                        }
                    }
                    &.link{
                        color:$base-link-color;
                        transition: opacity 0.3s ease;
                        &:hover{
                            @include opacity(0.7);
                        }
                    }
                    &.maintainHover{
                        color:$base-link-color;
                        i{
                            opacity: 1;
                            color:$base-link-color;
                        }
                    }
                }
                .list{
                    margin:0;
                    padding:0 0 45px;
                    border-right:none;
                    li{
                        .maintainHover{
                            color: $default;
                            i{
                                opacity: 0;
                            }
                            &:hover{
                                color:$base-link-color;
                                i{
                                    opacity: 1;
                                    color:$base-link-color;
                                }
                            }
                        }
                    }
                }
            }
            .divider{
                background-color:#ccc;
            }
            .popover{
                display:none;
                box-shadow: none;
                border-radius:0;
                border:none;
                width: 408px;
                max-width: none;
                padding:0;
                font-size:13px;
                line-height:15px;
                color: $default;
                font-weight:bold;
                // background-color: #f00;
                background:none;
                .item{
                    color:$base-link-color;
                    &:hover{
                        @include opacity(0.7);
                    }
                }
            }
        }
    }
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus{
    background:none;
}
.navbar-header {
    position:relative;
    z-index:100;
}
.nav-bar {
    margin: 0 auto;
    //max-width: 1168px;
    @extend %clearfix;
    //padding:0 10px;
}
.navbar {
    border-radius: 0;
    //height: 100px;
    margin:0;
    border:none;
    //padding:34px 0 0;

    .navbar-brand{
        padding:0;
        //margin-right:10px;
        position:relative;
        z-index: 999;
        height: auto;

    }
}
.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand{
    /*margin:0;
      margin-top: -10px;

    @media (max-width: 767px){
        margin-top: -4px;
    }*/
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form{
    margin:3px 0 0;
    padding:0;
    .btn-default{
        border-radius: 0;
        border:none;
        transition: opacity 0.3s ease;

        &:hover{
            background:none;
            @include opacity(0.6);
        }
    }
    .btn{
        font-size:18px;
        line-height:20px;
        padding:0;
        margin:0 0 0 28px;
        color:#222;
    }
}

// Tricks
.nav-holder.sticky{
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 1px rgba($default,.6);
}

/* disabilita sticky header  */
.nav-holder.sticky.sticky-off {
    position: static;
}

.nav-holder.bg-white {
    background-color: rgba(255,255,255,1);
}

// New
.navbar {
    height: 80px;
    padding: 0!important;

    .navbar-brand {
        display: block;
        line-height: inherit;

        @media (max-width: 767px){
            margin-left: 15px;
        }

        img {
            display: inline-block;
            width: 150px;
            height: auto;
            vertical-align: middle;

            @media (max-width: 767px){
                width: 130px;
            }
        }
    }

    &--logo {
        float: left;
        height: 80px;
        line-height: 80px;
    }
}

// Flex resolution
@media screen and (min-width: 768px){
    .navbar.navbar-defaut {
        height: 80px;
    }
    .navbar .flex-header {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        > * {
            height: auto!important;
            float: none!important;
            line-height: inherit!important;
        }
    }
}

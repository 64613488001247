
// Pointer to visualize the click window image Zommable
.open-win-full {
    cursor: pointer;
}

.win-fullscreen__content {
    position: relative;

    // Zoom container
    .add-zoom {
        display: inline-block;
        
        // aggiungo cursore zoom per capire meglio
        cursor: zoom-in;
    }

    // Zoom icon
    .zoom-icon {
        width: 30px;
        fill: #CECECE;
        position: absolute;
        bottom: 5px;
        right: 0;
        z-index: 100;

        @media (min-width: 992px){
            right: 15px;
        }
    }
}
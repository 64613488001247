.filter-banner {
  background-color: $bluscm;
  padding: 20px;
  color: white;
  display: grid;
  gap : 20px;
  grid-template-columns: 32px 1fr;
  
  svg {
      width: 32px;
      height: 32px;
      fill: #657487;
  }

  h4 {
      margin-block: 0 8px;
      font-size: 14px;
      line-height: 1.1;
  }

  p {
      font-size: 12px;
      margin: 0;
      font-weight: 500;
  }
}

.content--asimmetric .filter-banner {
  padding: 30px 35px;
  margin-bottom: 30px;
}

.content-info{
	width:100%;
	overflow:hidden;
	padding:28px 7px 0 0;

	.row-hold{
		width:100%;
		overflow:hidden;
    }
    
	.img-box{
		width:48.15%;
		float:left;
		overflow:hidden;
		margin:0 3.7% 25px 0;
		position:relative;
		img{
			width:100%;
			height:auto;
		}

		&::after{
			content: '\3009';
			position: absolute;
			bottom: 10px;
			right: 0px;
			font-size: 50px;
			font-weight: 200;
			color: #fff;
			//font-family: 'HelveticaNeueThin', Helvetica, Arial, sans-serif;
			font-family: "Helvetica", Arial, sans-serif;
			  text-rendering: optimizeLegibility;
			  -webkit-font-smoothing: antialiased;
			  -moz-osx-font-smoothing: grayscale;
			z-index: 9;

			@include opacity(0);
			@include single-transition(opacity,.5s,ease-in-out,0s);
		}

		&:hover{
			&::after{
				@include opacity(1);
			}
		}

	}
	.img-box:nth-child(even){
		margin:0 0 25px;
	}
	.text{
		position:absolute;
		top:29px;
		left:0;
		width:100%;
		padding:0 32px;
		color:#fff;
		font-weight:bold;
		p{
			margin:0;
			&.event-meta{
				margin-bottom: .5em;
			}
		}
		h2{
			font-size:36px;
			line-height:40px;
			font-weight:normal;
			margin:0 0 9px;
			a{
				color:#fff;
				&:hover{
					text-decoration:none;
					color:$base-link-color;
				}
			}
		}
	}
        /* Aggiustamento per versione brick */
        &.content-info-brick {
            .img-box::after{
                bottom: 44px;
            }
        }
}
.aside{
	width:100%;
	overflow:hidden;
	padding:26px 0 0 17px;
	.box{
		width:100%;
		overflow:hidden;
		margin:0 0 62px;
		color: $default;
		line-height:21px;
		&.news{
			margin:0 0 20px;
			h2{
				margin:0 0 18px;
			}
			.date{
				margin:0 0 5px;
			}
			p{
				margin:0 0 24px;
			}
		}
	}
	h2{
		font-size:15px;
		line-height:17px;
		color: $default;
		margin:0 0 11px;
		font-weight:bold;
		text-transform:uppercase;
	}
	h3{
		font-size:36px;
		line-height:40px;
		color: $primary-color;
		margin:0 0 2px;
		letter-spacing: -1px;
		a{
			color: $primary-color;
			transition: opacity 0.3s ease;
			&:hover{
				text-decoration:none;
				@include opacity(0.6);
			}
		}
	}
	.date{
		margin:0 0 25px;
		font-weight:bold;
		display:block;
	}
	.img-box{
		width:100%;
		overflow:hidden;
		margin:0 0 9px;
	}
}

.list-logos{
	width:100%;
	@extend %listreset;
	overflow:hidden;
	text-align:center;
	line-height:0;
	padding:0 0 11px;
	li{
		display:inline-block;
		padding:0 4px 15px;
		font-size:11px;
		line-height:13px;
		letter-spacing: normal;
		color:#999;
	}
	a{
		display:inline-block;
		vertical-align:top;
		&:hover{
			text-decoration:none;
			img{
				@include opacity(0.6);
			}
		}
		img{
			transition: opacity 0.3s ease;
		}
	}
	span{
		display:inline-block;
		vertical-align:top;
	}
}
.section-visual{
	width:100%;
	overflow:hidden;
	position:relative;
	background: url('/website/static/images/header-image-default.jpg') no-repeat;
	background-position: center center;
	background-size: cover;
    min-height: 280px;
    
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($primary-color,.2);
        width: 100%;
        height: 100%;
        z-index: 2;
    }

	.add-menu-sticky & {
        margin-top: 75px;

        @media (min-width: 767px) {
            margin-top: 80px;
        }
    }

	.inner--new & {
		min-height: 200px;

		@media (max-width: 480px){
			min-height: 150px;
		}
	}

	.is-landing & {
		min-height: 118px;

		@media (max-width: 767px){
			display: none;
		}
	}

	.text-hold {
		position:absolute;
		bottom:32px;
		left:0;
        width:100%;
        z-index: 5;
	}
	.text-info{
		max-width: 1168px;
		padding:0 10px;
		margin:0 auto;
		overflow:hidden;
		color:#fff;

		@media (max-width: 767px){
			padding: 0 15px;
		}
	}
	.breadcrumb{
		background:none;
		border-radius: 0;
		margin:0 0 10px;
		padding:0;
        font-weight:bold;
        
		li.active{
            color:#fff;
        }
        
        a {
            color:#fff!important;
            
			&:hover{
				text-decoration:none;
				color:$base-link-color;
			}
		}
    }
    
	h1 {
		/*font-size:50px;
		line-height:54px;*/
		margin:0;
	}
}
.breadcrumb > li+li:before {
	content: "/";
	padding:0 5px 0 2px;
	color:#fff;
}
.inner .breadcrumb > li:after{
	content: "/";
	padding:0 0 0 5px;
	color:#fff;
}

.main-content {
	//line-height:22px;

	/*@include breakpoint($mobile){
		line-height:18px;
    }*/
    padding-bottom: 50px;

	p {
		margin:0 0 19px;
	}
	a:not(.btn) {
		@include link-color($base-link-color);
		//color: $base-link-color;

		&:hover {
			text-decoration: underline;
		}
	}

	/*.btn{
		position:relative;
		border-radius:2px;
		//color:$base-link-color;
		text-align:left;

		&.btn-primary{
			color:$white;
			background: $primary-color;

			&:hover{
				background: rgba($primary-color,.8);
            }
            
		}
		&.btn-more{
			&:after{
				content:'';
				position: absolute;
				right:15px;
				width:9px;
				height:9px;
				top:50%;
				margin-top:-5px;
				border:2px solid #fff;
				border-width:0 0 2px 2px;
				-moz-transform: rotate(225deg);
				-ms-transform: rotate(225deg);
				-webkit-transform: rotate(225deg);
				transform: rotate(225deg);
				z-index: 10;
			}
		}
		&.btn-video{
			float:none !important;
			min-width:156px;
			height:35px;
			padding:5px 27px 5px 7px;
			&:after{
				content:'';
				position:absolute;
				right:13px;
				top:50%;
				margin-top:-7px;
				width: 0;
				height: 0;
				border-top: 7px solid transparent;
				border-bottom: 7px solid transparent;
				border-left: 10px solid $blue;
			}
			&:hover{
				color:$blue;
			}
			@include breakpoint($mobile){
				margin-bottom:20px;
			}
		}
	}*/
}

.section{
	padding: 38px 0;
	@extend %clearfix;
	@include breakpoint($mobile){
		padding:20px 0;
	}
	&.search-item-holder{
		padding:0;
		margin:0 0 56px;
		overflow:visible;
	}
	.text-box{
		margin-bottom:20px;
		overflow:hidden;

        /*&-2{
			margin-bottom:9px;
		}*/

        @include breakpoint($mobile){
			margin-bottom:0;
		}

        @include breakpoint($mobile){
            span{
                width: 100%;
                float: left!important;
                margin-bottom: 15px;
            }

            h1{

              img{
                  height: 20px;
                  width: auto;
              }
            }
        }

    }

    .text-box_titles{
        float: left;
        width: 80%;
    }

    .text-box_subtitle {
        @extend .text-box_titles;
        color: #777;
    }

    .title_first { float: left; width: 100%; }

    .single-prod-box div>div {
        margin: 10px 0;
    }

	h2{
		color:$base-link-color;
		margin:0 0 18px;

		@include breakpoint($mobile){
			font-size:26px;
			line-height:39px;
		}
		small{
			display:block;
			font-size:15px;
			line-height:18px;
			margin: 5px 0 0;
			font-weight:bold;
		}
	}
	h3{
		text-transform:uppercase;
		color:#4f4f4f;
		margin:0 0 10px;
	}
	.img-box{
		margin: 5px 0 0;
		position:relative;
		@include vat(100%, 307px);
		border:1px solid $grey-border;
		text-align:center;
		&-2{
			margin-top:0;
		}
		@include breakpoint($mobile){
			@include vat(100%, 150px);
			margin-bottom:10px;
		}
		img{
			display:inline-block;
			vertical-align:middle;
			max-width:100%;
			height:auto;
			white-space:normal;
		}
		.btn-plus{
			position:absolute;
			right: 15px;
			bottom: 8px;
			font-weight:bold;
			color:#b2b2b2;
			font-size:24px;
			line-height:1;

			&:hover {
				cursor: pointer;
				text-decoration: none;
			}
		}
	}
	.img-area{
		display:block;
		img{
			@include img-responsive;
		}
	}
}
.video-box{
	margin-bottom:20px;
	overflow:hidden;
	@include breakpoint($mobile){
		margin-bottom:0;
	}
	img{
		@include img-responsive;
		margin-bottom: 26px;
		@include breakpoint($mobile){
			margin-bottom:10px;
		}
	}
}
.section-holder{
	margin-bottom: 50px;

	&::after{
		content: '';
		display: block;
		margin: 0 15px;
		padding-top: 50px;
		border-bottom: 1px solid $grey-border;
	}


	@include breakpoint($mobile){
		margin-bottom:20px;
	}
	&-2{
		margin-bottom:36px;
	}
	&:last-child{
		margin-bottom: 11px;
	}
	h2{
		margin-bottom:20px;
		@include breakpoint($mobile){
			margin-bottom:10px;
		}
	}
	.btn{
		float:right;
	}

    .table{
        img{
            max-width: inherit;
        }
    }
}

#request-info{
	border-bottom: none;

        margin-top: -100px;
        padding-top: 100px;

	&::after{
		border: none;
	}

    @include breakpoint($mobile){
        #sector{
            height: 40px;
        }

        .form-horizontal{
            .row>div{
                margin-bottom: 10px;
            }
        }
    }
}

.item-holder{
	margin-bottom: -28px;
	overflow:hidden;
}
.item{
	//float:left;

    @include breakpoint($mobile){
		float:left;
		width:100%;
		height:auto !important;
		margin-bottom:20px;

        .specchietto{
            float: left;
            margin-bottom: 10px;
            width: 100%;

            .caption{
                float: left;
                width: 100%;

                .btn-more{
                    position: relative;
                    bottom: 0;
                    float: right;
                }
            }
        }
	}

	.search-item-holder &{
		margin-bottom:4px;
		@include breakpoint($mobile){
			margin-bottom:24px;
		}
	}
	&:hover{
		background: #f0f0f0;

		.btn-box{
			display:block;
		}

		.img-holder{
			img{
				-webkit-transform: scale3d(1.1,1.1,1);
				transform: scale3d(1.1, 1.1, 1);
			}
		}
	}
	.thumbnail{
		.img-holder{
			padding: 0;
			/*box-shadow: inset 0 0 1px rgba(0,0,0,.1);*/
			border: 1px solid $grey-border;
			overflow: hidden;

			@include vat(100%, 184px);


			@include breakpoint($mobile){
				@include vat(100%, 130px);
			}

			img{
				//@include single-transition(transform, .4s, ease, 0s);

                -webkit-transition: -webkit-transform 0.4s ease 0s;
                -moz-transition: transform 0.4s ease 0s;
                transition: transform 0.4s ease 0s;

                .c-product & {
                    max-width: 70%;
                }

            }
		}
		h3{
			margin:7px 0 20px !important;
			@include mq($until: widescreen){
				font-size:25px;
				line-height:28px;
			}
		}
	}
	p{
		margin:0 0 3px;
	}
	.btn-more{
		display:none;
		position:absolute;
		right:0;
		bottom:-20px;
	}
	.btn-box{
		display:none;
		position:absolute;
		left:-15px;
		right:-15px;
		bottom:-51px;
		padding:0 14px 16px 0;
		text-align:right;
		background:#f0f0f0;
		z-index:101;
		.btn-more{
			width:auto;
			right:auto;
			bottom:auto;
			position:relative;
			display:inline-block;
		}
	}
}
.thumbnail-holder{
	margin:0 0 -20px;
	overflow:hidden;
	@include breakpoint($mobile){
		margin-bottom:-10px;
	}
}
.thumbnail{
	position:relative;
	margin: 10px 0 30px;

	background:none;
	border:none;

	&:hover{
		.btn-more{
			display:inline-block;
		}
	}
	&-2{
		padding:2px 0 0;
	}
	.search-item-holder &{
		padding:15px 0 0;
	}
	.caption{
		position:relative;
		padding:9px 0;
		z-index:12;
		@include breakpoint($mobile){
			height:auto !important;
			padding:0;
		}
		p{
			margin:0 0 10px !important;
		}
	}
	.img-holder{
		display:block;
		background:#fff;
		padding: 0;
		margin:0 0 10px;
		text-align:center;
		overflow:hidden;
		@include vat(100%, 138px);
		.search-item-holder &{
			margin-bottom:4px;
			min-height:138px !important;
			&:after{
				height:138px;
			}
		}
		@include breakpoint($mobile){
			@include vat(100%, 130px);
			padding:5px;
		}
		img{
			display:inline-block;
			vertical-align:middle;
			max-width:100%;
			height:auto;
			white-space:normal;

			@include breakpoint($mobile){
				width:100%;
			}
		}
	}
	h3{
		text-transform:none;
		margin:0 0 10px !important;
		@include mq($until: widescreen){
			font-size:18px;
			line-height:22px;
		}
		@include breakpoint($mobile){
			margin:0 0 5px;
		}
		.search-item-holder &{
			text-transform:none !important;
			margin:0 !important;
		}
		small{
			display:block;
			margin-top:5px;
			font-weight:bold;
			font-size:15px;
			line-height:18px;
			color:#5b5b5b;
			text-transform:none;
			.search-item-holder &{
				line-height: 24px;
				margin-top: 2px;
			}
		}
	}
}
.logotype{
	float:right;
	margin-top:13px;
	@include breakpoint($mobile){
		margin: 0 0 10px;
		float:none;
	}
	img{
		vertical-align:top;
	}
}
.product-list{
	font-size:24px;
	font-weight:normal;
	overflow:hidden;
	@include breakpoint($mobile){
		font-size:16px;
		margin-bottom:10px;
	}
	dt{
		display:block;
		font-size:15px;
		line-height:18px !important;
		margin:0 0 4px;
		color:#4f4f4f;
	}
	dd{
		line-height:30px;
		margin:0 0 29px;
		color:#606060;
		@include breakpoint($mobile){
			line-height:22px;
			margin-bottom:10px;
		}
		a{
			display:inline-block;
			@include link-color(#606060);

			/*color: #606060;
			&:hover {
				color: #606060;
				text-decoration: none;
			}*/
		}
	}
}
.table{
	border-collapse:collapse;
	border:1px solid #ddd;
	color:#818285;
	font-size:14px;
	line-height:21px !important;
	@include breakpoint($mobile){
		font-size:12px;
		margin:0;
	}
	&.benefits-table{
		td{
			padding: 8px 6px;
			&:first-child{
				width:18%;
				padding-top: 14px;
				text-align:center;
			}
		}
	}
	&-2{
		tr{
			background:#f7f7f7;
			&:first-child{
				background:none;
			}
		}
		td,
		th{
			padding: 8px 7px !important;
			&:first-child{
				width:50.5%;
			}
			&:nth-child(2){
				width:17.5%;
			}
		}
	}
	td,
	th{
		border-right:1px solid #ddd;
		&:last-child{
			border-right:none;
		}
	}
}
.inner-form{
	label{
		display:block;
		font-weight:normal;
	}
	.form-group{
		display:block;
		margin-bottom: 17px;
	}
	.form-control,
	input[type="text"],
	input[type="email"],
	input[type="search"]{
		display:block;
		width:100% !important;
		border-radius:0;
		box-shadow:none;
		height:35px;
	}
	textarea{
		min-height:116px;
		height:116px;
	}
	.text{
		font-size:10px;
		line-height:14px;
		letter-spacing:-0.5px;
		p{
			margin:0 0 22px;
		}
	}
	.title{
		display:block;
		margin-top:-2px;
		font-weight:bold;
		text-transform:uppercase;
	}
	.btn{
		letter-spacing:1px;
	}
	.holder{
		margin-bottom:29px;
		overflow:hidden;
		@include breakpoint($mobile){
			margin-bottom:10px;
		}
	}
	&.sort-form{
		@include breakpoint($mobile){
			padding:20px 0 0;
		}
		&.active{
			.btn-close{
				display:block !important;
				float:right;
			}
			.opener{
				display:none;
			}
		}
		.opener{
			display:none;
			text-align:center;
			width:100px;
			@include breakpoint($mobile){
				display:block;
			}
		}
		.title-2{
			display:block;
			font-size:15px;
			line-height:18px;
			font-weight:bold;
			text-transform:uppercase;
			margin:0 0 27px;
			@include breakpoint($mobile){
				margin-bottom:10px;
			}
		}
		h3{
			font-size:15px;
			line-height:18px;
			font-weight:bold;
			margin:0 0 7px;
		}
		label{
			display:inline-block;
			vertical-align:top;
			margin:0;
		}
		.form-group{
			margin-bottom:5px;
		}
	}
	&.search-form-2{
		border:1px solid #ccc;
		margin-bottom: 27px;
		@include breakpoint($mobile){
			margin-bottom:15px;
		}
		.form-group{
			margin:0;
			overflow:hidden;
		}
		.form-control{
			border:none;
			box-shadow:none;
			font-size:24px;
			line-height:27px;
			padding:8px 13px;
			height:49px;
			color:#2a2a2a;
			&::-webkit-input-placeholder {color: #2a2a2a;}
			&:-moz-placeholder {
				color: #2a2a2a;
			}
			&::-moz-placeholder {
				color: #2a2a2a;
			}
			&:-ms-input-placeholder {
				color: #2a2a2a;
			}
			@include breakpoint($mobile){
				font-size:16px;
				line-height:20px;
				height:30px;
				padding:5px 10px;
			}
		}
	}
	.btn-search{
		float:right;
		font-size: 19px;
		margin: 15px 12px 0;
		background:none;
		border:none;
		&:hover{
			opacity:.8;
		}
		@include breakpoint($mobile){
			margin: 6px 0 0;
		}
	}
}
.text-search{
	display:block;
	font-weight:bold;
	margin-bottom:9px;
	@include breakpoint($mobile){
		margin-bottom:4px;
	}
}
.slide.js-slide-hidden{
	@media only screen and (min-width:768px){
		display: block !important;
		left: 0 !important;
		position:static !important;
		top: 0 !important;
	}
}
.jcf-checkbox {
	float:left;
	position: relative;
	overflow: hidden;
	margin: 0 9px 0 0;
	height: 16px;
	width: 16px;
	cursor: default;
	background: $white;
	border: 1px solid rgba(0, 0, 0, 0.2);
	@include breakpoint($mobile){
		margin-top:2px;
	}
	&.jcf-checked{
		border-radius:2px;
		span{display:block;}
	}
	span{
		position:absolute;
		display:none;
		height:8px;
		width:8px;
		top:50%;
		left:50%;
		margin:-4px 0 0 -4px;
		background:$blue;
	}
}


#content{
	width:100%;
	overflow:hidden;
    padding:33px 0 0 6px;
    
	&.content{
		overflow:visible;
    }
    
	h2 {
		margin:0 0 20px;
    }
    
	.txt{
		display:block;
		margin:0 0 25px;
    }
    
	.block {
		width:100%;
		overflow:hidden;
		margin:0 0 62px;
        line-height:22px;
        
		&:last-child {
			margin:0;
		}
	}
	.box-hold{
		width:100%;
		overflow:hidden;
		margin:0 0 25px;
	}
	.box {
		float:left;
		width:31.11%;
        margin:0 3.3% 0 0;
        
		&:nth-child(3){
			margin:0;
        }
        
		img{
			width:100%;
			height:auto;
        }
        
		.img-hold{
			border:1px solid #ccc;
			overflow:hidden;
			margin:0 0 14px;
		}
    }
    
	.hold-box{
		width:100%;
		overflow:hidden;
        padding:20px 0 0;
        
		.img-box {
			float:left;
			margin:0 27px 0 0;
			border:1px solid #ccc;
		}
		.text {
			overflow:hidden;
		}
	}
}

#request-info{
	border-bottom: none;
    margin-top: -100px;
    padding-top: 100px;

	&:after{
		border: none;
	}

    @include breakpoint($mobile){
        #sector{
            height: 40px;
        }

        .form-horizontal{
            .row>div{
                margin-bottom: 10px;
            }
        }
    }
}

.item-holder {
	margin-bottom: -28px;
	overflow:hidden;
}
.item {
	//float:left;

    @include breakpoint($mobile){
		float:left;
		width:100%;
		height:auto !important;
		margin-bottom:20px;

        .specchietto{
            float: left;
            margin-bottom: 10px;
            width: 100%;

            .caption{
                float: left;
                width: 100%;

                .btn-more{
                    position: relative;
                    bottom: 0;
                    float: right;
                }
            }
        }
	}

	.search-item-holder &{
		margin-bottom:4px;
		@include breakpoint($mobile){
			margin-bottom:24px;
		}
	}
	&:hover{
		background:#f0f0f0;
		.btn-box{
			display:block;
		}

		.img-holder{
			img{
				-webkit-transform: scale3d(1.1,1.1,1);
				transform: scale3d(1.1, 1.1, 1);
			}
		}
	}
	.thumbnail{
		.img-holder{
			padding: 0;
			/*box-shadow: inset 0 0 1px rgba(0,0,0,.1);*/
			border: 1px solid $grey-border;
			overflow: hidden;

			@include vat(100%, 184px);


			@include breakpoint($mobile){
				@include vat(100%, 130px);
			}

			img{
				//@include single-transition(transform, .4s, ease, 0s);

                -webkit-transition: -webkit-transform 0.4s ease 0s;
                -moz-transition: transform 0.4s ease 0s;
                transition: transform 0.4s ease 0s;

                .c-product & {
                    max-width: 70%;
                }

            }
		}
		h3{
			margin:7px 0 20px !important;
			@include mq($until: widescreen){
				font-size:25px;
				line-height:28px;
			}
		}
	}
	p{
		margin:0 0 3px;
	}
	.btn-more{
		display:none;
		position:absolute;
		right:0;
		bottom:-20px;
	}
	.btn-box{
		display:none;
		position:absolute;
		left:-15px;
		right:-15px;
		bottom:-51px;
		padding:0 14px 16px 0;
		text-align:right;
		background:#f0f0f0;
		z-index:101;
		.btn-more{
			width:auto;
			right:auto;
			bottom:auto;
			position:relative;
			display:inline-block;
		}
	}
}
.thumbnail-holder{
	margin:0 0 -20px;
	overflow:hidden;
	@include breakpoint($mobile){
		margin-bottom:-10px;
	}
}
.thumbnail{
	position:relative;
	margin: 10px 0 30px;

	background:none;
	border:none;

	a.brand {
		display: inline-block;
	}

	&:hover{
		.btn-more{
			display:inline-block;
		}
	}
	&-2{
		padding:2px 0 0;
	}
	.search-item-holder &{
		padding:15px 0 0;
	}
	.caption{
		position:relative;
		padding:9px 0;
		z-index:12;
		@include breakpoint($mobile){
			height:auto !important;
			padding:0;
		}
		p{
			margin:0 0 10px !important;
		}
	}
	.img-holder{
		display:block;
		background:#fff;
		padding: 0;
		margin:0 0 10px;
		text-align:center;
		overflow:hidden;
		@include vat(100%, 138px);
		.search-item-holder &{
			margin-bottom:4px;
			min-height:138px !important;
			&:after{
				height:138px;
			}
		}
		@include breakpoint($mobile){
			@include vat(100%, 130px);
			padding:5px;
		}
		img{
			display:inline-block;
			vertical-align:middle;
			max-width:100%;
			height:auto;
			white-space:normal;

			@include breakpoint($mobile){
				width:100%;
			}
		}
	}
	h3{
		text-transform:none;
		margin:0 0 10px !important;
		@include mq($until: widescreen){
			font-size:18px;
			line-height:22px;
		}
		@include breakpoint($mobile){
			margin:0 0 5px;
		}
		.search-item-holder &{
			text-transform:none !important;
			margin:0 !important;
		}
		small{
			display:block;
			margin-top:5px;
			font-weight:bold;
			font-size:15px;
			line-height:18px;
			color:#5b5b5b;
			text-transform:none;
			.search-item-holder &{
				line-height: 24px;
				margin-top: 2px;
			}
		}
	}
}
.logotype{
	float:right;
	margin-top:13px;
	@include breakpoint($mobile){
		margin: 0 0 10px;
		float:none;
	}
	img{
		vertical-align:top;
	}
}
.product-list{
	font-size:24px;
	font-weight:normal;
	overflow:hidden;
	@include breakpoint($mobile){
		font-size:16px;
		margin-bottom:10px;
	}
	dt{
		display:block;
		font-size:15px;
		line-height:18px !important;
		margin:0 0 4px;
		color:#4f4f4f;
	}
	dd{
		line-height:30px;
		margin:0 0 29px;
		color:#606060;
		@include breakpoint($mobile){
			line-height:22px;
			margin-bottom:10px;
		}
		a{
			display:inline-block;
			@include link-color(#606060);

			/*color: #606060;
			&:hover {
				color: #606060;
				text-decoration: none;
			}*/
		}
	}
}
.table{
	border-collapse:collapse;
	border:1px solid #ddd;
	color:#818285;
	font-size:14px;
	line-height:21px !important;
	@include breakpoint($mobile){
		font-size:12px;
		margin:0;
	}
	&.benefits-table{
		td{
			padding: 8px 6px;
			&:first-child{
				width:18%;
				padding-top: 14px;
				text-align:center;
			}
		}
	}
	&-2{
		tr{
			background:#f7f7f7;
			&:first-child{
				background:none;
			}
		}
		td,
		th{
			padding: 8px 7px !important;
			&:first-child{
				width:50.5%;
			}
			&:nth-child(2){
				width:17.5%;
			}
		}
	}
	td,
	th{
		border-right:1px solid #ddd;
		&:last-child{
			border-right:none;
		}
	}
}
.inner-form{
	label{
		display:block;
		font-weight:normal;
	}
	.form-group{
		display:block;
		margin-bottom: 17px;
	}
	.form-control,
	input[type="text"],
	input[type="email"],
	input[type="search"]{
		display:block;
		width:100% !important;
		border-radius:0;
		box-shadow:none;
		height:35px;
	}
	textarea{
		min-height:116px;
		height:116px;
	}
	.text{
		font-size:10px;
		line-height:14px;
		letter-spacing:-0.5px;
		p{
			margin:0 0 22px;
		}
	}
	.title{
		display:block;
		margin-top:-2px;
		font-weight:bold;
		text-transform:uppercase;
	}
	.btn{
		letter-spacing:1px;
	}
	.holder{
		margin-bottom:29px;
		overflow:hidden;
		@include breakpoint($mobile){
			margin-bottom:10px;
		}
	}
	&.sort-form{
		@include breakpoint($mobile){
			padding:20px 0 0;
		}
		&.active{
			.btn-close{
				display:block !important;
				float:right;
			}
			.opener{
				display:none;
			}
		}
		.opener{
			display:none;
			text-align:center;
			width:100px;
			@include breakpoint($mobile){
				display:block;
			}
		}
		.title-2{
			display:block;
			font-size:15px;
			line-height:18px;
			font-weight:bold;
			text-transform:uppercase;
			margin:0 0 27px;
			@include breakpoint($mobile){
				margin-bottom:10px;
			}
		}
		h3{
			font-size:15px;
			line-height:18px;
			font-weight:bold;
			margin:0 0 7px;
		}
		label{
			display:inline-block;
			vertical-align:top;
			margin:0;
		}
		.form-group{
			margin-bottom:5px;
		}
	}
	&.search-form-2{
		border:1px solid #ccc;
		margin-bottom: 27px;
		@include breakpoint($mobile){
			margin-bottom:15px;
		}
		.form-group{
			margin:0;
			overflow:hidden;
		}
		.form-control{
			border:none;
			box-shadow:none;
			font-size:24px;
			line-height:27px;
			padding:8px 13px;
			height:49px;
			color:#2a2a2a;
			&::-webkit-input-placeholder {color: #2a2a2a;}
			&:-moz-placeholder {
				color: #2a2a2a;
			}
			&::-moz-placeholder {
				color: #2a2a2a;
			}
			&:-ms-input-placeholder {
				color: #2a2a2a;
			}
			@include breakpoint($mobile){
				font-size:16px;
				line-height:20px;
				height:30px;
				padding:5px 10px;
			}
		}
	}
	.btn-search{
		float:right;
		font-size: 19px;
		margin: 15px 12px 0;
		background:none;
		border:none;
		&:hover{
			opacity:.8;
		}
		@include breakpoint($mobile){
			margin: 6px 0 0;
		}
	}
}
.text-search{
	display:block;
	font-weight:bold;
	margin-bottom:9px;
	@include breakpoint($mobile){
		margin-bottom:4px;
	}
}
.slide.js-slide-hidden{
	@media only screen and (min-width:768px){
		display: block !important;
		left: 0 !important;
		position:static !important;
		top: 0 !important;
	}
}
.jcf-checkbox {
	float:left;
	position: relative;
	overflow: hidden;
	margin: 2px 9px 0 0;
	height: 16px;
	width: 16px;
	cursor: default;
	background: $white;
	border: 1px solid rgba(0, 0, 0, 0.2);
	@include breakpoint($mobile){
		margin-top:2px;
	}
	&.jcf-checked{
		border-radius:2px;
		span{display:block;}
	}
	span{
		position:absolute;
		display:none;
		height:8px;
		width:8px;
		top:50%;
		left:50%;
		margin:-4px 0 0 -4px;
		background:$blue;
	}
}


#content{
	width:100%;
	overflow:hidden;
    padding:33px 0 0 6px;
    
	&.content{
		overflow:visible;
    }
    
	.txt{
		display:block;
		margin:0 0 25px;
	}
	.block{
		width:100%;
		overflow:hidden;
		margin:0 0 62px;
		line-height:22px;
		&:last-child{
			margin:0;
		}
	}
	.box-hold{
		width:100%;
		overflow:hidden;
		margin:0 0 25px;
	}
	.box{
		float:left;
		width:31.11%;
        margin:0 3.3% 0 0;
        
		&:nth-child(3){
			margin:0;
		}
		img{
			width:100%;
			height:auto;
		}
		.img-hold{
			border:1px solid #ccc;
			overflow:hidden;
			margin:0 0 14px;
		}
	}
	.hold-box{
		width:100%;
		overflow:hidden;
		padding:20px 0 0;
		.img-box{
			float:left;
			margin:0 27px 0 0;
			border:1px solid #ccc;
		}
		.text{
			overflow:hidden;
		}
	}
}

.popover-content{
	padding:4px 0 0 26px;
	.title{
		display:block;
		font-size:22px;
		line-height:24px;
		color:#999;
		font-weight:normal;
		margin:0 0 8px;
	}
}

.popover-menu2{
  border-right: 0 !important;
}


// Event Part
.c-event {
	&__content {
		margin-top: 30px;
	}
}

// Read more

.read-more {
	position: relative;
	cursor: pointer;

	&:after {
		content: 'discover more';
		display: inline-block!important;
		text-transform: uppercase;
		font-weight: 600;
		color: $bluscm;

		.site-it & {
			content: 'scopri di più';
		}
	}

	> div {
		display: none;
	}

	&.all-text {
		cursor: default;

		> div {
			display: block;
			//height: auto;
		}

		&:after {
			content: 'close';
			cursor: pointer;

			.site-it & {
				content: 'chiudi';
			}
		}
	}

	.icon {
		position: absolute;
		bottom: 1px;
		font-size: 20px;

		&.fa-angle-down {
			left: 135px;

			.site-it & {
				left: 115px;
			}
		}

		&.fa-angle-up {
			left: 65px;
		}

	}

}

// Parti di contenuto
#content {
	.area-gallery-carousel .gallery {
		margin-top: 30px;
	}
}

.main-content {
    &.custom-bg {
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 100%;
    }
}


.spot-image {
    background-color: $white;

    &--news {
        min-height: 470px;
        position: relative;

        @media (min-width: 1200px){
            min-height: 490px;
        }

        @media (max-width: 767px){
            min-height: inherit;
            height: auto;
            margin-bottom: 30px;
        }

        h4 {
            max-height: 3.6em;
            height: auto;
            overflow: hidden;

            &:after {
                content: "";
                text-align: right;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 2.2em;
                background: linear-gradient(to right, rgba(255,255,255,0), $white 95%);
            }
        }

        .spot-image__text {

            /*@media (max-width: 1200px){
                min-height: 255px;
            }*/

            h4 {
                overflow: hidden;
                margin-top: 0;
            }

            .btn {
                position: absolute;
                bottom: 30px;
                left: 35px;

                @media (max-width: 1200px){
                    bottom: 20px;
                    left: 20px;
                }
            }
        }
    }

    .date {
        font-size: $font-size-small;
        text-transform: uppercase;
        display: inline-block;
        margin-bottom: 8px;
    }

    .spot-mosaic-left & {
        height: 550px;
        position: relative;
    }

    .spot-mosaic-right & {
        height: 262px;
        position: relative;
    }

    @media (max-width: 767px){
        .spot-mosaic-left &,
        .spot-mosaic-right & {
            height: auto;
        }
    }
}

.spot {

    &--mosaic {
        height: 550px;
        position: relative;

        @media (max-width: 767px){
            height: auto;
        }
    }

    &--bg {
        color: $white;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 12;
            background-color: rgba(0,0,0,.5);
        }

        .hi-spots-bg & {
            min-height: 350px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @media (max-width: 767px) {
                min-height: 150px;
                margin: 30px 0;
            }
        }

        .spot-mosaic & {
            height: 262px;
            margin-bottom: 26px;

            @media (max-width: 767px){
                height: auto;
            }
        }
    }

    &--padd1 {
        padding: 80px 40px;
    }

    &--padd2 {
        padding: 30px 35px;

        &.spot--bg {
            min-height: 420px;
        }
    }

    &--padd1,
    &--padd2 {

        .content--asimmetric & {
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 40px;
        }

        .spot__text {
            padding: 0;
        }
    }

    &--fixed {
        min-height: inherit;
        height: 494px;

        @media (max-width: 767px){
            height: auto;
        }

        h4 {
            max-height: 3.6em;
            height: auto;
            overflow: hidden;

            &:after {
                content: "";
                text-align: right;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 2.2em;
                background: linear-gradient(to right, rgba(255,255,255,0), $grey-light 95%);
            }
        }
    }

    &--fairs {
        background-color: #00a9e0;

        .spot-image__img {
            text-align: center;
            border: 1px solid $grey-light;
            background-color: white;
            height: 190px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            background-image: url('/website/static/images/hiteco/techframe.png');
            background-position: top right;
            background-size: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        .spot-image__img img {
            padding: 10px 0;
        }

        .spot-image__text {
            color: white;

            h4 {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical; 
                overflow: hidden; 
            }
        }
    }

    // Mobile
    &--search {
        margin-bottom: 0!important;

        .s-prodlist & {
            margin-bottom: 30px!important;
        }

        .h4 {
            margin: 0;
            margin-bottom: 15px;
        }
    }
}

.spot,
.spot-image {
    position: relative;

    @media (min-width: 768px){
        &:hover .btn:before {
            width: 100%;
        }
    }

    &__text {
        position: relative;
        padding: 30px 35px;
        z-index: 15;

        @media (max-width: 1200px){
            padding: 20px;
            min-height: 255px;
        }

        h4,.h4 {
            margin-top: 0;
            display: block;
        }

        .h2 {
            display: block;
            margin-top: 0;
        }

        p {
            margin-bottom: 40px;
        }

        .spot-mosaic & {
            .btn {
                position: absolute;
                bottom: 30px;
                left: 35px;

                @media (max-width: 1200px) {
                    bottom: 20px;
                    left: 20px;
                }
            }

            p {
                margin-bottom: 0;
            }
        }

        /*.spot-mosaic-right & {
            padding: 20px 25px;

            .btn {
                bottom: 20px;
                left: 25px;
            }
        }*/

        .text {
            width: 100%;
            margin: 0 0 1em 0;
        }
        .text > .mini-desc {
            margin: 0;
        }
    }

    &__link {
        @include full;
    }

    &--colored {
        background-color: $grey-light; 
    }
}

.spot-content {
    padding: 30px;

    @media (min-width: 767px){
        padding: 40px 45px;
    }

    .h2,
    h2 {
        margin-top: 0;
        margin-bottom: 15px;
    }

    p {
        line-height: 1.6;
        margin-bottom: 0px;
        //margin-bottom: 20px;
    }

    &--colored {
        background-color: $bg-spot;
        text-align: center;

        .h3 {
            margin-top: 0;
        }
    }

    .content--asimmetric & {
        margin-bottom: 30px;
    }

    .btn {
        //font-size: 16px;
        margin-top: 20px;
        line-height: 1.4;
    }
}

.spot-box {
    background-color: $grey-light;

    @media (max-width: 767px){
        background-image: none!important;
    }

    > .site-wrap {
        position: relative;
        height: 100%;
    }

    &__caption {
        min-height: 260px;
        padding: 40px;
        position: absolute;
        bottom: 0;
        left: 30px;
        color: $white;
        //font-weight: lighter;

        &:hover .btn:before {
            width: 100%;
        }

        @media (max-width: 767px){
            position: static;
            left: 0;
            padding: 20px;
            min-height: inherit;
            background-color: $grey-foot;
        }
    }

    &--high {
        height: 435px;

        @media (max-width: 767px){
            height: auto;
        }
    }

    &--low {
        height: 260px;

        @media (max-width: 767px){
            height: auto;
        }

        .spot-box__caption {
            height: 100%;
        }
    }

    .btn {
        position: absolute;
        bottom: 40px;
        left: 40px;

        @media (max-width: 767px){
            position: static;
        }
    }
}

.spot-file {
    padding-left: 55px;
    position: relative;
    margin-bottom: 0px;
    min-height: 90px;

    .press-files & {
        min-height: inherit;
    }

    .h4 {
        margin-top: 10px;
        display: inline-block;
        width: 100%;
        word-break: break-word;
    }

    .fa {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 45px;
        color: $grey-foot;
    }

    .fa-file-pdf-o {

    }

    &--little {
        padding-left: 25px;
        margin-bottom: 10px;

        .fa {
            font-size: 16px;
        }
    }

    .btn {
        cursor: pointer;
    }
}

.spot-video {
    margin-bottom: 30px;

    > .embed-responsive {
        border: 1px solid $grey-light;
    }

    &__title {
        margin-top: 10px;

        > span {
            font-weight: 600;
        }

        &:after {
            width: 40%;
        }
    }

    h2,p {
        display: none;
    }
}

:root {
    --spot-bg-primary: #f0f0f0;
    --spot-bg-secondary: #326295;
    --spot-color-dark: #333;
    --spot-color-light: #ffffff;
}

// Spot colored customized from SCM
.spot--bg {
    &.rm-overlay:after {
        display: none;
    }   
}
.spot--colored {
    &--grey {
        background-color: var(--spot-bg-primary);
    }
    &--secondary {
        background-color: var(--spot-bg-secondary);
    }
    &.color-dark {
        color: var(--spot-color-dark);
    }
    &.color-light {
        color: var(--spot-color-light);
    }
    &.spot--md {
        display: flex;
        min-height: 35vh;
    }
    &.spot--lg {
        display: flex;
        min-height: 50vh;
    }
    &.spot--xl {
        display: flex;
        min-height: 75vh;
    }
    &.spot--flex-start {
        align-items: flex-start;
    }
    &.spot--flex-end {
        align-items: flex-end;
    }
    &.spot--align-center {
        align-items: center;
    }
}

// Spot
$bg-spot: rgba(193, 203, 223, 0.45);

// Grey Scale
$grey-light: #f0f0f0;
$grey-bg: #f7f7f7;
$grey-form: #fafafa;
$grey-multi: #4a4a4a;
$grey-border: #606060;
$grey-foot: #9b9b9b;
$grey-hover: #eaeaea;
$table-grey-bg: #fcfcfc;
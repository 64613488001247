
.a-search {
    #content {
        padding: 50px 0;
        min-height: 50vh;
    }

    .media {
        .media-left {
            width: 100%;
            margin-top: 15px;
            margin-bottom: 15px;

            @media (min-width: 992px){
                width: auto;
                margin-bottom: 0;
            }
        }
    }
}

.search {
    &__form {
        //max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        margin-bottom: 45px;

        @media (min-width: 992px){
            flex-direction: row;
            margin-bottom: 0;
        }

        .form-control {
            padding: 15px;
            margin-bottom: 0;
            height: auto;
            //text-align: center;
        }

        input[type=submit] {
            width: 250px;  
        }

        .btn {
            width: 100%;

            @media (min-width: 992px){
                width: auto;
                margin-left: 15px;
            }
        }
    }

    .text-hold {
        .a-search & {
            text-align: center;
        }
    }
}
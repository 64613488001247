/* Scrollbar */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    background-color: transparent;
    
    @media (min-width:992px){
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #E4E4E4;
    }
}
::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;

    @media (min-width:992px){
        background-color: transparent;
    }
}
::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: 10px solid transparent;

    @media (min-width:992px){
        border: 10px solid rgba(0,0,0,.2);
    }
}

// Windows fullscreen
html.form-is-open,
html.win-is-open {
    overflow: hidden;
}

html {
    scroll-behavior: smooth;
}

body {
    &.s-www_hiteco_net {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 300;
    }
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
    line-height: 1.4;

    .pimcore_area_wysiwyg & {
        margin-top: .35em;
        margin-bottom: .55em;
    }
}

.h1,.h2,.h3,.h4,.h5,.h6 {
    display: inline-block;
}

// Tricks - Desktop / Risoluzione tablet - Enea
@media (max-width: 1024px) and (min-width: 768px) {
	html.no-touchevents,
    .no-touchevents body {
        min-width: 1024px;
    }
}
// ----- //

a {
	text-decoration: none;
	color: $base-link-color;
	transition: background 0.3s ease, color 0.3s ease;

	&:hover {
		text-decoration: underline;
	}
}

img {
	vertical-align:top;
	max-width: 100%;
	height: auto;
}

.container-fluid{
	max-width: 1178px;
	margin: 0 auto;

        .breadcrumb {
            margin-bottom: 1em;
            list-style: none;
            font-size: 90%;
            background: none;
            padding: 0;
            border: 0;
            font-weight: 600;

            strong {
                font-weight: normal;
            }
            b {
                font-weight: normal;
            }
            a {
                @include link-color($breadcrumb-active-color);
                //color: $breadcrumb-active-color;
                font-weight: 200;

				/*&:hover {
			      color: lighten($breadcrumb-active-color, 15%);
			      text-decoration: none;
			  }*/
            }
        }
}

#wrapper {
	width:100%;
	overflow:hidden;
	position:relative;

	//@include single-transition(background-color,.25s,ease-in-out,.5s);

	&::after{
		background-color: transparent;
	}


	/*&.overlay{

		@media (min-width: 768px){
			&::after{
				content:'';
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				width:100%;
				height:100%;
				background-color: rgba(0,0,0,.2);
				z-index: 10;
			}
		}

	}*/
}

.header {
    position:absolute;
    top:0;
    left:0;
    @extend %clearfix;
    height:136px;
    z-index: 1000;
    width:100%;

    &--new {
        position: static;
        height: auto;
        border-bottom: 1px solid rgba(0,0,0,.05);
    }

    &__top-panel {
        @extend %clearfix;
        //padding: 10px 0;
        background-color: $grey-default;
        font-size: 13px;

        /*@media (max-width: 767px){
            padding: 10px 15px!important;
        }*/

        &-cont {
            margin: 0 auto;

            @media (max-width: 767px){
                padding: 0;

                .inf {
                    margin: 0;
                }

                .sticky & {
                    height: 110px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 100;
                }
            }
        }
    }
}


.top-panel {
    &--remove {
        display: none;
    }

    .inf {
        float:left;
        margin: 0 10px 0 0;

       .select-country{
            margin: 0;
            top: -11px;
            left: -15px;
            padding: 44px 0 16px;
            box-shadow: 0 0 21px rgba(0,0,0,.2);
            background: #fff;
            border-radius: 0;
            border: 0;

            @media (min-width: 768px){
                min-width: 213px;
            }


            li{
                font-size: 13px;
                line-height: 17px;
                white-space: normal;
                display: block;
                margin-bottom: 4px;
                //padding: 3px 10px 3px 37px;
                padding: 0 15px;

                a{
                    font-weight: bold;
                    display: inline-block;
                    width: 100%;
                    white-space: normal;
                    color: $default!important;
                    padding: 4px 0;

                    &:hover{
                        color: $bluscm!important;
                    }
                }
            }
        }
    }
}

#main{
    width:100%;
    overflow:hidden;
}


#label-select-country.flag-icon {
    /*color: white;*/
    width: 100%;
    padding-left: 30px;
    background-size: contain;

    @media (max-width: 480px){
        padding-left: 23px;
    }

    &.world {
        background-size: 20%;
    }

}

/*.top-panel .inf {

	.dropdown {
		padding-right: 0;

		&.open {
			a,
			a > span,
			a > i {
				color: $default!important;
			}

			a:hover,
			a:hover > span,
			a:hover > i {
				color: $base-link-color!important;
			}
		}
	}
}*/

.select-country {
    z-index: 9999;
    overflow: visible !important;

    /*li {
        padding: 4px 15px!important;
    }*/

    li > a {
        padding-left: 30px!important;

        &.world {
            background-size: 13%!important;
            padding: 5px 30px!important;
        }

        .select-languages & {
            //padding-left: 0!important;
        }

    }
}

.top-panel .reserved-menu {
    //margin-right: 30px;

    .dropdown-menu li > a {
        padding-left: 0!important;
    }

    @media (max-width: 767px){
        margin-right: 15px;
    }

}

// Tricks
@media (max-width: 767px) {
    .add-menu-sticky .single-image {
        margin-top: 0;
    }
    .sticky .header__top-panel-cont {
        left: 15px!important;
    }

    .navbar .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}

// Header Top - New Style
.header__top-panel {
    padding-top: 0;
    padding-bottom: 0;
}
.header__top-panel-cont {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header__right,
    .header__left {
        width: 50%;
    }
}

.header__lang {
    ul {
        display: flex;
        justify-content: flex-start;

        border: none;
        margin: 0;
        box-shadow: none;
        background-clip: none;
        background-color: transparent;
        float: none;
        position: static;
        overflow: inherit;
        min-width: inherit;

        li {
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }
        }

        .flag-icon {
            text-indent: -1999px;
            background-size: cover;
            background-position: center;
            width: 22px;
            height: 22px;
            border-radius: 50%;
        }
    }

    .dropdown-menu > li > a {
        padding: 0!important;
    } 
}

.header__right {
    
    .box-social ul a {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background-color: transparent;
        color: white!important;
        border-radius: 50%;

        &.youtube-play {
            background-color: #FF0100;
        }
        &.linkedin {
            background-color: #0075B2;
        }
    }
}
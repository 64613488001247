
.calc {
    &__form {
        float: right;

        label {
            color: $default;
        }
    }

    &__cont {
        max-width: 600px;
        margin: auto;
        margin-bottom: 40px;
        padding: 30px;
        //padding-top: 0;

        //border-radius: 5px;
        border: none;
        //box-shadow: 0 0 10px #f0f0f0;
        background-color: $grey-light;

        h1 {
            margin-bottom: 40px;
            text-align: center;
            color: $bluscm;
            line-height: 1.2;
        }

        .alert {
            clear: left;
            float: left;
            display: none;
        }
    }

    &__input {
        width: 200px;
        margin-bottom: 10px;
        padding-left: 30px;
    }

    &__btn {
        background-color: orange;
        padding: 0 15px;
        height: 42px;
        line-height: 42px;
    }

    &__table {
        clear: left;
        float: left;
        margin-top: 50px;
        width: 100%;

        .lbl {
            font-weight: bold;
            text-align: right;
            width: 60%;

            &.big {
                font-size: 18px;
            }

            &--min {
                font-weight: normal!important;
                font-size: 14px;
            }
        }

        .value {
            width: 40%;
        }

        tr > td {
            /*border: 1px solid #ccc;*/
            border: none;
            vertical-align: top;
            padding: 10px;
        }

        &-line {
            border-bottom: 1px solid $grey-border;
        }
    }

    &__input-cont {
        position: relative;

        &:before {
            content: '\f155';
            font-family: fontAwesome;
            display: inline-block;
            position: absolute;
            top: 7px;
            left: 10px;
            font-weight: bold;
        }

        .fa {
            position: absolute;
            top: 10px;
            left: 10px;
        }
    }

}

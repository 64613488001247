.pimcore_area_ExteraForm {

  form {

    .has-error {
      color: $state-danger-text;
    }

    ul.alert-danger {
      display: none;
    }
  }

  fieldset legend {
    margin-bottom: 16px;
  }
}

form.contact {
  .contact-message,
  .contact-sector {
    height: 137px;
  }
  .contact-acceptance {
    margin-top: 30px!important;

    &.has-error {
      color: $state-danger-text;
    }
  }

  ul.alert li {
    display: block;
    list-style: none;
  }

}

// Comuni
fieldset.well {
  legend {
    width: auto;
    padding: 0 .4em;
    margin: 0;
    background-color: #fff;
    font-size: inherit;
    font-weight: bold;
    font-size: 80%;
    text-transform: uppercase;
  }
}

.pimcore_area_ExteraForm form .form-group label,
form.contact label.control-label {
  font-weight: normal;
  &.required:after {
    color: #e32;
    content: ' *';
    display: inline;
  }
}

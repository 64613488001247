.inf {
    &__list {
        float: left;
        margin-right: 15px;
        @extend %listreset;
        @extend %clearfix;
        padding: 2px 0 0;

        /*&:last-child {
            margin-right: 0;
        }*/

        .dropdown-menu {
            @media (max-width: 767px){
				min-width: 160px;
			}
        }
    }

    &__list--country {
        margin-right: 30px;

        @media (max-width: 600px){
            margin-right: 20px;
        }

        @media (max-width: 480px){
            margin-right: 15px;
        }
    }

    &__list--reserved,
    &__list--languages {
        margin-right: 0;
    }

    &__list--reserved {
        .dropdown-menu {
            @media (max-width: 767px){
                right: -30px!important;
                left: auto!important;
            }
        }
    }

    &__list-item {
        float:left;
        //padding: 0 24px 0 0;

        > a {
            //text-decoration: none!important;
            color: $default;

            &:active,
            &:focus,
            &:hover {
                text-decoration: none!important;
                //color: $base-link-color;
            }
        }

        a > i {
            vertical-align: middle;
            padding: 0 0 0 5px;
            position: absolute;
            top: 3px;
            left: 100%;

            @media (max-width: 767px) {
                padding: 0;
            }
        }

        &.dropdown.open {
            > a {
                //color: $base-link-color;
                position: relative;
                z-index: 99999;
                //font-weight: bold;
            }

            a > i {
                top: 2px;

                @media (max-width: 767px) {
                    padding: 0 0 0 5px;
                }
            }
        }

        .dropdown-menu li a {
            &:hover {
                background-color: transparent;
            }
        }
    }
}

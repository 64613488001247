form {

    //@extend .clearfix;

    .contact-acceptance {
        padding-top: 0!important;
        margin-top: 0!important;

        p {
            margin-bottom: 0!important;
        }
    }

    @media (min-width: 768px){
        .add-margin {
            margin-top: 20px!important;
        }
    }

    .centered {
        text-align: center;
        float: none;
    }

    .btn-lg {
        padding: 10px 16px!important;
        font-size: 18px!important;
        margin: 0 auto!important;
        margin-top: 20px;
        float: none;
    }
}

#form_error.alert-danger {
    margin-left: 15px;
    margin-right: 15px;
}

// Custom form gdpr
.gdpr-form,
.pimcore_area_ExteraForm form {
    @extend .form-horizontal;
    background-color: transparent!important;

    .form-group {
        margin-left: 0!important;
        margin-right: 0!important;
    }

    textarea {
        height: 80px!important;
    }

    .contact-acceptance.checkbox {
        label {
            display: none;
        }

        input[type=checkbox] {
            margin-left: 0;
        }

        .hint {
            padding-left: 25px;
        }
    }

    .style-select:before {
        z-index: 2;
    }

    .control-label {
        text-align: left;
        display: inline-block;
        margin-bottom: 10px;

        // test per hiteco e casadei
        margin-top: -14px!important;
    }

    input[type=checkbox].form-control {
        display: inline-block;
        width: auto;
        margin: 0;
        padding: 0;
        margin-right: 5px;
        margin-bottom: 10px;
    }

    // gdpr item
    .gdpr-item-newsletter {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;

        input[type=checkbox] {
            margin-top: 0px;
            margin-right: 10px;
        }
    }
    .gdpr-item {
        margin-bottom: 20px;

        .form-fullscreen & {
            @media (min-width: 992px){
                font-size: 12px;
            }
        }

        .control-label {
            margin-left: 5px;
            margin-bottom: 0px;
            padding: 0;
            font-size: 14px;
            display: inline-block;

            .form-fullscreen & {
                @media (min-width: 992px){
                    font-size: inherit;
                }
            }
        }

        input[type=checkbox] {
            margin-top: 0px;
            position: relative;
            top: 1px;
        }

        // extera text modal link
        [data-extera-gdpr] {
            display: inline-block;
            margin-bottom: 1.5rem;
        }

        p.hint {
            margin-bottom: 5px;
        }

        .jcf-checkbox span {
            top: 7px!important;
            left: 21px!important;
        }
    }

    // con gdpr
    .btn {
        float: left!important;
    }
}

// Gdpr form
.gdpr-form {
    .gdpr-item {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        padding-top: 0;
        margin-bottom: 20px;

        .form-fullscreen & {
            margin-bottom: 1rem;
        }

        .control-label {
            font-weight: bold;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 15px;
        }

        input[type=checkbox].form-control {
            width: auto!important;
            margin: 0!important;
            height: auto!important;
            /*position: absolute;
            top: auto;
            bottom: 6px;
            left: 15px;*/
        }
    }

    .btn {
        float: left;
    }

}

// disclaimer form gdpr compliant
.form-disclaimer {
    margin-bottom: 1rem;
    font-size: 1.3rem;
    font-weight: bold;

    .jcf-checkbox,
    input[type=checkbox] {
        display: none;
    }
}

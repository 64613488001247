/**

N.B - NON USATELO PIU' 

**/

@media screen and (min-width: 768px){
	#sidebar{
		address{
			.tel{
				cursor: default;
				pointer-events: none;
			}
		}
	}
}
@media screen and (max-width: 1014px){
	.nav-holder{
		.navbar-nav{
			li{
				margin: 0 0 0 29px;
			}
		}
	}
}
@media screen and (max-width: 1023px){
	.carousel-caption{
		bottom: 25%;
                .titolo-slider {
                    font-size:40px;
                    line-height:46px;
                }
	}
	#content{
		padding:33px 0 0;
	}
}
@media screen and (max-width:991px){
	#sidebar{
		.box{
			width:33.33%;
			float:left;
		}
	}
	.nav-holder{
		.navbar-nav{
			font-size:12px;
			line-height:16px;
			li{
				margin:0 0 0 15px;
			}
			.dropdown-menu{
				ul{
					width:250px;
					font-size:12px;
					line-height:14px;
					li{
						margin:0 0 8px;
					}
					.list{
						padding:0 0 15px;
					}
				}
				.popover{
					width:300px;
				}
			}
		}
	}
	.popover-content{
		.title{
			font-size:18px;
			line-height:20px;
		}
	}
}
@media screen and (max-width: 767px){
	.content-info{
		padding:28px 0 0;
		.img-box{
			width:100%;
			float:none;
			margin:0 0 25px;
		}
	}
	.carousel-caption{
		display:none;
	}
	.aside{
		padding:0;
		.box{
			margin:0 0 20px;
		}
		h3{
			font-size:26px;
			line-height:32px;
		}
	}
	.navbar{
		.navbar-brand{
			+img {
				height: auto;
				width: 220px;
			}
		}
	}
	.top-panel{
		font-size:12px;
		//line-height:14px;
		//padding:5px;
		.inf{
			ul{
				li{
					padding:0 5px 0 0;
				}
			}
		}
		.box-social{
			width:150px;

			span{
				padding:2px 4px 0 0;
			}
		}
	}
	#sidebar{
		padding:0 0 20px;
		.box{
			width:100%;
			float:none;
		}
	}
	#content{
		padding:20px 0 0;
		h2{
			font-size:30px;
			line-height:32px;
		}
		.block{
			margin:0 0 20px;
		}
		.box{
			float:none;
			width:100%;
		}
		.hold-box{
			.img-box{
				float:none;
				margin:0 0 20px;
			}
			img{
				width:100%;
				height:auto;
			}
		}
		.box-hold{
			margin:0 0 15px;
		}
	}
	.navbar-default .navbar-collapse,
	.navbar-default .navbar-form{
		margin:3px -15px 0;
	}
	.nav-holder{
		//height:40px;

		.navbar-nav{
			font-size:15px;
			line-height:18px;
			li{
				float:none;
				padding:0 0 10px;
			}
			.dropdown-menu{
				position: static;
				padding:0;
				margin: 0;
				float: none;
				ul{
					padding:15px 0 0;
					margin:10px 0 0;
					width:300px;
					border-right:none;
					li{
						margin:0 0 15px;
						padding:0;
					}
				}
				.popover{
					position:static;
					padding:10px 0 15px;
					margin:15px 0 0;
					border-bottom:1px solid #ccc;
					border-top:1px solid #ccc;
				}
			}
		}
	}
	.navbar-default .navbar-collapse,
	.navbar-default .navbar-form{
		box-shadow: none;
		border-color:#fff;
		overflow:hidden;
		background:#fff;
	}
	.navbar{
		height:40px;
		padding:12px 0 0;
		min-height: inherit;
	}
	.navbar-default{
		.navbar-toggle{
			margin:-8px 0 0;
			border-color:$primary-color;
		}
	}
	.navbar-default .navbar-toggle:hover,
	.navbar-default .navbar-toggle:focus{
		background-color:$primary-color;
	}
	.section-visual{
		img{
			height:200px;
		}
	}
	.navbar-toggle{
		padding:8px;
	}
	.popover-content{
		padding:4px 0 0;
	}
}

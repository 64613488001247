
.s-prodlist__faq {
    h5 {
        margin-bottom: 0;
    }
}

.pimcore_area_faq {
    padding: 20px 0;

    .read-more {
        max-height: 110px;

        .btn {
            font-size: 16px;
            cursor: pointer;
        }

        &:before {
            height: 70px;
        }
    }
}
.c-product {
  .hi-spots-bg,
  .hi-product-img {
    margin-top: 30px;
  }
}

.current-product {
  margin-left: 0px;
  margin-top: 10px;

  li {
    padding: 7px 0;
    color: $secondary-color;
  }

  &__item {
  }
}

.site-sidebar__menu ul li ul {
  margin-left: 5px;
}

.site-sidebar {
  &__filters {
    display: block !important;
    position: fixed;
    inset: 0;
    z-index: 100;
    background-color: white;
    overflow: auto;
    font-family: $montserrat-font;
    transform: translateX(-100%);
    will-change: transform;
    transition: transform .3s ease-out;
    padding-inline: 15px;

    &.open {
      transform: translateX(0);
    }

    @media screen and (min-width: 768px) {
      position: static;
      z-index: auto;
      transform: none;
      transition: none;
      will-change: unset;
      padding-inline: 0;
      padding-block-end: 20px;
      margin-block-end: 20px;
      border-bottom: 1px solid #cacaca;
    }
  }

  &__filters-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 16px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &__filters-title {
    font-size: 16px;
    margin: 0;
  }

  &__filters-close {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &__filters-collpser {
    color: $bluscm !important;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
  }

  &__filter-block {
    padding-block: 18px 28px;
    border-top: 1px solid #cacaca;
    border-bottom: 1px solid #cacaca;

    @media screen and (min-width: 768px) {
      padding: 8px;
      border: 1px solid #cacaca;
      border-radius: 4px;

      & + & {
        margin-top: 20px;
      }
    }
  }

  &__filter-block-header {
    display: flex;

    @media screen and (min-width: 768px) {
      position: relative;
      cursor: pointer;

      &::after {
        content: '\f106';
        position: absolute;
        top: -2px;
        right: 0;
        font-family: 'FontAwesome';
      }

      &.closed::after {
        content: '\f107';
      }
    }

    h4 {
      margin: 0;
      font-size: 14px;
    }
  }

  &__filter-block-values {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding-block-start: 18px;

    @media screen and (min-width: 768px) {
      gap: 8px;
    }
    
    &--grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, 72px);
      gap: 15px;

      @media screen and (min-width: 768px) {
        justify-content: center;
        gap: 8px;
      }

      label {
        aspect-ratio: 1/1;
        padding-inline: 0 !important;
      }
    }
  }
  
  &__filter-input {
    position: relative;
    
    input {
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;
      opacity: 0;
    }

    label {
      display: flex;
      flex-direction: column;
      border: 1px solid #cacaca;
      border-radius: 5px;
      padding: 10px;
      align-items: center;
      text-align: center;
      font-size: 9px;
      line-height: 1.1;
      text-transform: uppercase;
      margin: 0;
      gap: 5px;
      transition: border-color .3s, background-color .3s, color .3s;
      will-change: border-color, background-color, color;

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          border-color: $bluscm;
        }
      }
      
      span {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }
      
      img {
        max-width: 24px;
        border-radius: 5px;
      }  
    }

    input:checked + label {
      background-color: $bluscm;
      border-color: $bluscm;
      color: white;
    }

    input:disabled + label {
      opacity: .25;
      cursor: not-allowed;
    }
  }

  &__filters-form-footer {
    padding-block: 15px;
    background-color: white;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
      display: none;
    }
    
    button {
      outline: none;
      font-size: 9px;
      line-height: 1.1;
      text-transform: uppercase;
      padding: 10px 30px;
      border: 1px solid;
      border-radius: 100px;
      font-weight: 700;

      &[type="reset"] {
        border-color: #cacaca;
        background-color: white;
      }

      &[type="submit"] {
        border-color: $bluscm;
        background-color: $bluscm;
        color: white;
      }
    }
  }
}

.s-prodlist {
  @media (max-width: 767px) {
    .spot-content--colored {
      margin-bottom: 30px;
    }
  }

  &__filter-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cacaca;
    padding: 8px 16px;
    font-family: $headings-font-family;
    font-size: 12px;
    text-decoration: none !important;

    @media (min-width: 768px) {
      display: none;
    }

    span {
      text-transform: uppercase;
      color: black;
      font-weight: 500;
      line-height: 1.1;
    }

    svg {
      max-width: 28px;
    }
  }

  &__title {
    margin-bottom: 32px;

    @media (max-width: 992px) {
      font-size: 32px;
    }
  }

  &__info {
    //max-width: 80%;
    margin: 40px 0 10px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 20px;
    }
  }

  &__toolbar {
    line-height: 30px;
    margin-top: 30px;
    margin-bottom: 15px;
    padding: 0 0 10px;

    .anchor {
      margin-right: 15px;
    }

    .lbl {
      float: right;
    }

    ul {
      margin: 0;
      padding: 0;
      height: 30px;
      line-height: 30px;
      margin-left: 5px;
      float: right;
    }
  }

  .toolbar-item {
    cursor: pointer;
    display: inline-block;
    list-style: none;
    margin-left: 10px;
    height: 30px;
    text-align: center;

    .fa {
      font-size: 25px;
      color: $secondary-color;
      height: 30px;
    }

    &.active .fa {
      color: $default;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__item {
    padding: 20px;
    border: 1px solid $secondary-color;
    position: relative;
    //margin-bottom: 30px;
    //min-height: 455px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .pimcore_area_product & {
      min-height: 385px;
    }

    .s-www_scmgroup_ru & {
      min-height: 450px;
    }

    @media (max-width: 767px) {
      min-height: inherit;
    }

    .product-info & {
      min-height: inherit;

      h5 {
        word-wrap: break-word;
      }
    }

    @media (max-width: 1080px) {
      padding: 15px;
    }

    &:hover {
      @include transition(border 0.25s ease-in-out);

      border-color: $default;
    }

    &.media {
      display: table !important;
      min-height: inherit;
    }

    .with-rows & {
      padding: 0;
      padding-bottom: 20px;
      margin-top: 20px;
      margin-bottom: 0;
      border: none;
      border-bottom: 2px solid $grey-light;

      &:hover {
        border-color: $secondary-color;
      }
    }

    img {
      margin-bottom: 37px;
    }

    h4 {
      margin-top: 0;
      display: block;
      font-weight: 400;
    }

    .info {
      color: $grey-foot;
      display: inline-block;
      margin-bottom: 13px;

      .product-info & {
        margin-bottom: 0;
      }

      .with-rows & {
        padding-left: 40px;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .text {
      width: 100%;
      margin: 0 0 1em 0;
    }
    .text > .mini-desc {
      margin: 0;
      font-size: 14px;
    }

    .media-heading {
      font-size: 22px;
      font-weight: 300;
      margin-bottom: 5px;
    }

    .media-heading > .info {
      margin-bottom: 0;
    }

    .media-object {
      width: 160px;
      margin-bottom: 0;
      max-width: inherit;
      padding-right: 40px;
    }

    .media-body {
      display: none;
    }

    .mini-desc p {
      text-align: inherit !important;
      strong,
      br {
        display: none;
      }
    }

    .mini-desc ul {
      //ist-style: none;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-left: 16px;
      line-height: 1.4;

      > li {
        //display: inline;
        list-style-type: circle;
      }
    }

    .info img {
      margin-bottom: 0;
    }
  }

  &__grid {
    //display: none;
    margin-top: 40px;

    //@include transition(all .25s ease-in-out);
    @media (max-width: 767px) {
      margin-top: 30px;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      //width: 100%;
      min-width: 1px;
    }

    .with-cols .row {
      flex-direction: row;
    }

    .with-rows .row {
      flex-direction: column;
    }

    .col {
      //float: left;
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 30px;
    }

    &.with-cols .col {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;

      @media (min-width: 480px) {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media (min-width: 992px) {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
    }

    &.with-rows .col {
      width: 100%;
    }

    &.is-visible {
      display: block;
    }
  }
}

:root {
  --color-bg: #326295;
  --color-yt: #FF0100;
  --color-in: #0075B2;
}

.popup-banner {
  max-width: 85vw;
  width: 1200px;
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  transition: all .3s;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &::before {
    content: '';
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(#333, .3);
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__close {
    width: 44px;
    height: 44px;
    background: white;
    border-radius: 100%;
    position: absolute;
    top: -16px;
    right: -16px;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background-color: #333;
      position: absolute;
      top: 50%;
      left: 12px;
      margin-top: -1px;
      transform: rotate(45deg);
    }

    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background-color: #333;
      position: absolute;
      top: 50%;
      left: 12px;
      margin-top: -1px;
      transform: rotate(-45deg);
    }
  }

  &__title {
    font-size: 18px;
    text-align: right;
    flex-basis: calc(100% - 55px);
    max-width: calc(100% - 55px);
    margin: 0;
  }

  &__content {
    background-color: white;
    text-align: center;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 20px;

    .btn {
      font-size: 16px;
    }

    @media (min-width: 768px){
      padding: 30px 43px;
    }
  }

  // Newsletter
  &--newsletter {
    //background-color: white;
    font-size: 18px;
    max-width: 340px;

    header {
      padding: 30px;
      background-color: var(--color-bg);
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      @media (min-width: 768px){
        padding: 30px 43px;
      }

      svg {
        flex-basis: 55px;
        max-width: 55px;
      }
     }

    .social {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      > a {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        //background-color: transparent;
        color: white !important;
        border-radius: 50%;
      }

      .youtube-play {
        background-color: var(--color-yt);
      } 
      .linkedin {
        background-color: var(--color-in);
      }
    }

  }
}
@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot?-ppfzl2');
	src:url('../fonts/icomoon.eot?#iefix-ppfzl2') format('embedded-opentype'),
		url('../fonts/icomoon.woff?-ppfzl2') format('woff'),
		url('../fonts/icomoon.ttf?-ppfzl2') format('truetype'),
		url('../fonts/icomoon.svg?-ppfzl2#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}
[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-facebook:before {content: "\e601";}
.icon-youtube:before {content: "\e600";}
.icon-arrow-right:before {content: "\e60a";}
.icon-arrow-down:before {content: "\e602";}
.icon-search:before {content: "\e60c";}

.a-landingprodotto,
.a-landing {

    //Custom
    .container-fluid {
        max-width: 1280px;
    }
    // ----

    #wrapper {
        overflow: inherit;

        &.sticky {
            @media (max-width: 767px) {
                #header {
                    position: static;
                }
            }
        }
    }

    #header {
        .top-panel {
            padding: 10px 0;
            min-height: 40px;

            .header__top-panel-cont {
                max-width: 1280px;

                .sticky & {
                    left: 15px!important;
                }
            }

            @media (max-width: 767px){
                padding: 0 15px;
            }

            @media (min-width: 767px){
                .inf {
                    margin-left: 15px;
                }
            }

            .dropdown-toggle {
                color: #333;

                &:hover {
                    color: #333!important;
                }
            }
        }
    }

    &__content {
        min-height: 300px;
        margin: 25px auto;

        h1,h2,h3 {
            color: $bluscm;
        }
    }

    .nav-bar,
    #footer {
        max-width: inherit;

        .list-logos {
            display: none;
        }

        .cont-filiali {
            padding: 0;
        }
    }

    // Menù
    .navbar {
        height: 70px!important;
        line-height: 70px!important;
        padding: 0!important;

        @media (max-width: 767px){
            margin: 0 15px;
        }

        &--logo {
            height: 70px;
            line-height: 70px;
        }

        .navbar-brand {
            margin-top: 0;

            @media ( max-width: 767px){
                margin-left: 0;
            }
        }
    }
    .nav-holder {
        background-color: rgba(255,255,255,1);
        height: 70px;
    }

    .nav-holder.sticky {
        background-color: white;
    }

    .navbar-header {
        @media (max-width: 767px){
            width: 60%;
        }

        @media (min-width: 767px){
            margin-left: 15px;
        }
    }

    .menu-land {
        @media (min-width: 767px){
            margin-right: 15px;
        }

        ul {
            padding: 0;
            margin: 0;
        }
        &__item {
            list-style: none;
            > a {
                color: $default;
            }
        }
    }

    // Carousel
    #carousel-homepage {
        max-height: 580px;
    }

    .carousel-control {
        display: none;
    }

    .carousel-block {
        .item {
            max-height: 580px;
        }

        @media (max-width: 767px){
            .carousel-indicators {
                display: block;
                bottom: 3px;
            }
        }
    }

    .carousel-caption {
        bottom: 80px;

        @media (max-width: 767px){
            bottom: 40px;
            display: block;
        }

        p {
            text-align: center;
            margin: auto;

            &.titolo-slider {
                font-size: 32px;
                line-height: 1.1;

                @media (max-width: 767px) {
                    font-size: 23px;
                }
            }

            &.content-slider {
                font-size: 16px!important;

                @media (max-width: 767px){
                    display: none;
                }
            }
        }

        .btn {
            background-color: transparent!important;
            color: white!important;
            border: 1px solid white!important;
            padding: 10px 15px!important;
            margin-top: 20px;
            text-transform: uppercase;
            border-radius: 3px;

            @media (max-width: 767px){
                padding: 5px 10px!important;
            }

            &:after {
                display: none;
            }

            &:hover {
                background-color: white!important;
                color: #333!important;
            }
        }
    }

    .pimcore_area_gallery-folder,
    .pimcore_area_video {
        padding: 30px 0;
    }

    .area-gallery-folder {
        .thumbnail {
            margin: 0;

            img {
                //width: 100%;
            }
        }
        .zoomPopup {
            margin: 0;

            > div {
                padding: 0;
                //width: 16.66667%;
            }
        }
    }

    /*@media (max-width: 767px){
        .top-panel {
            display: none;
        }
    }*/
    .big-background {
        //height: 60.42vh;

        .mb_YTVPPlaypause.is-played,
        .mb_YTVPPlaypause {
            /*font-size: 85px;
            width: 112px;
            margin-left: -51px;*/
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            font-size: 93px;
            margin-left: 0;

            @media (max-width: 767px){
                font-size: 63px;
            }

            &::before {
                width: 130px;
                height: 130px;
                line-height: 130px;
                position: absolute;
                bottom: 40px;
                margin-left: -65px;
                padding: 0;

                @media (max-width: 767px){
                    width: 100px;
                    height: 100px;
                    line-height: 100px;
                    margin-left: -50px;
                    bottom: 20px;
                }
            }
        }

        .mb_YTVPPlaypause.is-played {
            font-size: 80px;

            @media (max-width: 767px){
                font-size: 50px;
            }
        }
    }
}

// Landing Careers
.a-landing {
    .entry-content {
        .btn {
            background-color: transparent!important;
            color: $bluscm!important;
            border: 1px solid $bluscm!important;
            padding: 10px 15px!important;
            text-transform: uppercase;
            border-radius: 3px;

            @media (max-width: 767px){
                padding: 5px 10px!important;
            }

            &:hover {
                background-color: $bluscm!important;
                color: white!important;
            }
        }
    }
}

// Differnce of Landing prodotto
.a-landingprodotto {

    #carousel-homepage {
        max-height: inherit;
    }

    #header {
        #list-select-language,
        #list-select-country {
            > a {
                color: white!important;

                &:hover {
                    color: white!important;
                    text-decoration: none!important;
                }
            }
        }
    }

    .carousel-block {
        .item {
            max-height: inherit;
        }
    }

    .menu-land {
        &__item {
            > a {
                //color: white;
                text-transform: uppercase;
            }
        }
    }
}

.cv-posizioni {
  &.item-holder {
    .item {
      .btn {
        visibility: hidden;
      }
      &:hover {
        .nophoto {
          color: $bluscm;
        }
        .btn {
          visibility: visible;
        }
      }

    }
  }
}
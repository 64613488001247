.pimcore_area_business-card-map {
    margin-bottom: 30px;
}

.pimcore_tag_checkbox {
    padding: 0!important;
    float: left;
    margin-right: 10px;

    hr {
        clear: left;
    }
}

.pimcore_tag_select {
    padding: 0!important;
}

.toolbox-spacer {

    > span {
        display: inline-block;
    }

    .spacer-none {
        height: 30px;
    }
    .spacer-50 {
        height: 50px;
    }
}


.win-fullscreen {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    pointer-events: none;
    opacity: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    transition: (opacity .5s ease-in-out 0s);

    -webkit-transform: translateZ(0);

    &:after,
    &:before {
        content: '';
        position: absolute;
        pointer-events: none;

        @supports (-webkit-appearance:none) {
            width: calc(100% + 15px);
            height: calc(100% + 15px);
            border: 1.5em solid rgba(#ccc,.85);
        }

        transform: translate3d(0, 0, 0);

        @media (max-width: 1024px){
            display: none;
        }
    }

    &--open {
        pointer-events: auto;
        opacity: 1;
        overflow: hidden;
    }

    .close-icon {
        position: absolute;
        top: 1.7em;
        right: 1.7em;
        cursor: pointer;
        width: 40px;
        height: 40px;

        @media (max-width: 767px){
            top: 15px;
            right: 15px;
            width: 20px;
            height: 20px;
        }

        &:hover {
            opacity: .8;
        }

        &:after,
        &:before {
            background-color: #ccc;
        }
    }
}

///////
// With form per scheda prodotto

.form-fullscreen {
    @extend .win-fullscreen;
    text-align: left;
    //background-color: $white;

    &.is-open {
        pointer-events: auto;
        opacity: 1;
        overflow: hidden;
    }

    .close-icon {
        z-index: 50;
    }

    @media (max-width: 767px){
        .form-wrap form > .row,
        .form-wrap > .row {
            margin: 0 -15px;
        }

        .form-wrap .head-form {
            margin-top: 15px;
        }
    }

}

// Scroller form row
.form-fullscreen__scroller {
    overflow: auto;
    margin-top: 66px;
    margin-bottom: 33px;
    padding: 1 2rem;

    @media (min-width: 992px){
        padding: 0 2rem
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px transparent;
        background-color: transparent;
        
        @media (min-width:992px){
            -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.1);
            background-color: #E4E4E4;
        }
    }
    &::-webkit-scrollbar {
        width: 3px;
        background-color: transparent;

        @media (min-width:992px){
            background-color: transparent;
        }
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border: 3px solid transparent;

        @media (min-width:992px){
            border: 3px solid rgba(0,0,0,.2);
        }
    }

    .ajax-form {
        margin-top: 0px;
    }

    .form-wrap {
        max-width: 1440px;
    }
}

// fix fullscreen generali post aggiornamento gdpr
.form-fullscreen {
    .form-horizontal .control-label, 
    .gdpr-form .control-label, 
    .pimcore_area_ExteraForm form .control-label {
        padding-bottom: 0px!important;
    }

    .form-horizontal .form-control, 
    .gdpr-form .form-control, 
    .pimcore_area_ExteraForm form .form-control {
        height: 35px!important;
    }

    .field-block textarea {
        min-height: 80px!important;
    }
}
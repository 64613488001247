// Add new fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,400&family=Montserrat:wght@700&display=swap');

// Custom variables and override bootstrap variables
$white: #fff;
$blue: #326295;
$grigio-chiaro: #e5e5e5;
$body-bg: #fff;
$base-text-color: #404040;
$base-link-color: #00A9E0;
$font-family-sans-serif: 'Lato','Arial',sans-serif;
$base-font-family: $font-family-sans-serif;
$base-font-size: 16px;
$base-line-height: 1.4;
$headings-font-family: 'Montserrat','Arial',sans-serif;
$headings-font-weight: 700;
$font-bold: $font-family-sans-serif;
$navbar-default-bg: transparent;

$table-border-color:#ddd;
$input-color:$base-text-color;

$font-size-base: 16px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1: floor(($font-size-base * 3)) !default; // ~36px
$font-size-h2: floor(($font-size-base * 2.4)) !default; // ~36px
$font-size-h3: ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.9)) !default; // ~12px

.product {
    &__title {
        margin-bottom: 10px;
        width: 100%;
    }

    &__subtitle {
        clear: left;
        margin-top: 0;
    }

    &__image {
        padding: 30px 0;
        text-align: center;

        @media (max-width: 767px){
            padding: 15px 0;
        }
    }

    &__catalogo{
        text-align: center;
        background-image: url(../../images/hiteco.catalogue.png);
        background-size: cover;
        background-position: center center;
        display: block;
        padding: 32px;
        font-size: 25px;
        @media (min-width: 767px) {
            padding: 64px 32px;
        }
        color: #ffffff !important;

        &:hover{
            color: #eeeeee !important;

            @media (min-width: 767px) {
                .product__catalogo__highlight{
                    padding-left: 64px;
                    padding-right: 64px;
                }
            }
        }

        span{
            display: inline-block;
            margin-bottom: 4px;
            &:last-child{
                margin-bottom: 0;
            }
        }

        &__scarica{
            text-transform: uppercase;
        }

        &__highlight{
            background-color: #ffffff;
            padding: 6px;
            color: #0f9cd8;
            font-weight: bold;
            text-transform: uppercase;
            transition: padding 0.2s;
            font-size: 45px;
        }

        &__gamma{
            font-style: italic;
        }
    }

    &__request {
        background-color: #f0f0f0;
        padding: 0 30px;
        height: 120px;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (min-width: 992px){
            flex-direction: row;
            justify-content: space-between;
        }

        .h3 {
            margin-bottom: 0;
        }

        p {
            font-size: 18px;
            margin-bottom: 0;
        }
    }
}

.product-content {
    .spot-content--colored {
        margin-top: 25px;
    }

    .cont-title {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 1024px){
            flex-direction: row;
            justify-content: space-between;            
        }

        > h1 {
            max-width: inherit;
            width: auto;
        }
    }
}



.product-info {
    padding-top: 70px;
    padding-bottom: 30px;

    @media (max-width: 767px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    > .h2 {
        margin-bottom: 30px;
        position: relative;
        
        > span {
            padding-right: 20px;
            background-color: white;
            position: relative;
            z-index: 10;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 12px;
            right: 0;
            width: 100%;
            height: 2px;
            background-color: #f0f0f0;
            z-index: 0;
        }

        @media (min-width: 992px){
            margin-bottom: 50px;
        }
    }

    .col-sm-4 {
        border-bottom: 1px solid #d0d0d0;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        @media (max-width: 767px){
            padding-left: 18px;
        }
    }

    .h4 {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 12px;

        &.h-prod__conf__type {
            margin-bottom: 35px;
        }

        &:first-letter {
            text-transform: uppercase;
        }
    }

    .advantage .h4 {
        display: block;
    }

    &.no-padding {
        padding-top: 0;
    }

    &.last {
        padding-bottom: 0;
    }

    .table-responsive {
        //width: 69%;
        width: auto;

        /*@media (max-width: 767px){
            width: 99%;
        }*/
    }

    .table-responsive .table {
        margin-bottom: 0;
    }

    &.with-col .table-responsive {
        width: 99%;
    }

    .flat-table {
        width: 100%!important;
        min-width: 600px;
    }

    &.with-col .flat-table {
        width: 100%!important;

        @media (min-width: 992px) {
            width: auto !important;
        }
    }

    .table {
        background-color: white;
        margin: auto;

       tr td {
            p {
                margin-bottom: 0;
            }
        }

        tr {
            background-color: white;
            
            &:nth-of-type(odd) {
                background-color: #f0f0f0;

                &:hover {
                    background-color: #f0f0f0;
                }
            }

            &:nth-of-type(1) {
                background-color: $primary-color;
                color: white;

                > td {
                    text-align: left!important;            
                    padding: 15px 30px;
                }

                > td > em {
                    font-style: normal;
                    text-transform: uppercase;
                    font-family: $headings-font-family;
                    font-size: 16px;
                    font-weight: bold;
                }

                &:hover {
                    background-color: $primary-color;
                }
            }

            &:hover {
                background-color: white;
            }
        }
    }

    &--catalog {
        padding: 30px;
        background-color: $primary-color;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        margin-bottom: 30px;

        svg {
            margin-bottom: 20px;
        }

        h4 {
            margin-bottom: 10px;
        }

        .full-link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }
    }
}

.text-impact {
    padding: 100px 0;
    max-width: 75%;
    margin: 0 auto;

    @media (max-width: 767px){
        max-width: 100%;
        padding: 50px 0;
    }

    &.centered {
        text-align: center;
    }

    &__title {
        margin-top: 0;
    }

    &__text {
        line-height: 2;
        font-size: 16px;

        li > span {
            font-family: $base-font-family!important;
        }
    }

    &.custom-padding {
        padding-bottom: 50px;
    }
}

.advantage {
    &__col {
        margin-bottom: 10px;

        p,    
        strong {
            word-break: break-word;
        }

        p > span {
            font-family: $font-family-sans-serif!important;
        }

        @media (max-width: 767px){
            margin-bottom: 30px;
        }
    }

    &__image {
        display: table;
        //border: 1px solid $secondary-color;
        background-color: $white;
        margin-bottom: 20px;
        //padding: 0 30px;
        width: 100%;
        height: 250px;
        line-height: 250px;
        text-align: center;

        @media (max-width: 767px){
            height: auto;
            line-height: inherit;
            margin-bottom: 15px;
        }

        img {
            width: auto;
            max-height: 250px;
        }
    }

    p {
        line-height: 1.4;
        margin-bottom: 0;
    }

    &.with-col {
        line-height: 1.6;
    }
}

.request-info {
    margin-top: 40px;

    input[type=email] {
        min-width: 240px;

        @media (max-width: 767px){
            text-align: center;
        }
    }

    .btn {
        cursor: pointer;

        @media (max-width: 767px) {
            margin-top: 10px;
            min-width: 240px;
            display: inline-block;
        }
    }
}

.cont-title {
    position: relative;

    > h1 {
        max-width: 80%;
    }

    .product__phone {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        vertical-align: middle;
        color: $primary-color;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 20px;
        font-weight: 600;

        .site-en_US & {

        }
    }
}

.h-prod {
    .product-info {
        table {
            margin-bottom: 30px;
        }
    }

    .advantage {
        &__col {

        }

        &__image {
            height: auto;
            line-height: normal;

            @media (max-width: 767px){
                text-align: left;
            }
        }
    }

    &__img {
        text-align: center;

        img {
            max-width: 100%;
            margin: auto;
        }
    }

    &__conf {
        &__type {
            font-weight: 600;
            text-transform: uppercase;        
        }

        &__file {
            padding-left: 65px;
            
            .h3 {
                margin-top: 0;
                display: block;
            }
        }
    }
}


input[type=text],
input[type=email],
input[type=password],
input[type=search],
select,
textarea {
    padding: 0px 20px;
    height: 50px;
    border: none;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    background-color: transparent;
    box-shadow: none;
    -webkit-appearance: none;

    font-size: 16px;
    font-weight: 300;

    &:focus {
        outline: none;
        box-shadow: none;
        border-color: #ccc;
    }
}

textarea {
    min-height: 140px;
}

// select style
.style-select {
    position: relative;
    background-color: $grey-bg;

    &::before {
        content: '\f078';
        font-family: fontAwesome;
        display: inline-block;
        position: absolute;
        top: 8px;
        right: 10px;
        color: $secondary-color;
        z-index: 0;
    }

    select {
        position: relative;
        z-index: 1;

        -webkit-appearance: none;
        appearance: none;

        //Firefox
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
    }
}

.input-full {
    width: 100%;
}

// Form product
.form-wrap {
    max-width: 1140px;
    margin: auto;
    padding: 0 15px;

    @media (max-height: 740px){
        margin: 40px 0;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }

}

// Form comuni già presenti
.form-horizontal {
    .field-block {
        min-height: 84px;
        position: relative;

        &.contact-acceptance {
            min-height: inherit;
        }
    }

    .head-form {
        margin-bottom: 20px;
    }

    .control-label {
        font-size: 16px;
        line-height: 1;
        font-weight: 300!important;
        font-family: $font-family-sans-serif;
        padding-top: 0;
        padding-bottom: 10px;
        display: inline-block;

        &.required:after {
            content: ' *';
            display: inline;
            color: $primary-color;
        }
    }

    .form-control {
        padding: 0px 20px;
        width: 100%;
        height: 50px;
        background: transparent;
        color: $base-font-family;
        margin-bottom: 10px;
        border: 1px solid #d0d0d0;
        border-radius: 5px;
    }

    .multiselect-container.dropdown-menu {
        display: block;
        position: static;
        float: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        height: 210px;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 5px;

        li > a {
            outline: 0;
            padding: 3px 15px;

            &:focus,
            &:hover {
                outline: 0;
            }

            .checkbox {
                padding-top: 0;
            }

            input[type=checkbox] {
                position: static;
                margin-left: 0;
                margin-top: 0;
                margin-right: 5px;
            }
        }
    }

    select[multiple] {
        height: 163px;

        option {
            padding: .25em .5em;
        }
    }

    .contact-acceptance {
        display: flex;
        align-items: center;

        &.add-margin {
            margin-top: 30px;    
        }
        &.checkbox {
            label {
                display: none;
            }
    
            input[type=checkbox] {
                margin-left: 0;
                width: auto;
            }
    
            .hint {
                padding-left: 0px;
                margin-left: 0;
            }
    
            &.has-error .hint {
                color: $state-danger-text;
            }

            &.has-error input[type=checkbox] {
                box-shadow: 0 1px 10px $state-danger-text;
            }
        }
    }

    .btn {
        float: right;
        @extend .btn--rounded;

        @media (max-width: 767px){
            margin-top: 15px;
        }
    }

    .field-with-description {
        position: relative;

        .hint {
            position: absolute;
            bottom: -30px;
            left: 15px;
        }
    }

    // Error
    .form-group {
        &.has-error{
            .alert {
                display: none;
            }

            .hint {
                color: $state-danger-text;
            }

            input[type=checkbox] {
                box-shadow: 0 1px 4px $state-danger-text;
            }
        }
    }
    .has-success .required:after {
        color: $state-success-text;
    }
    .has-error .required:after {
        color: $state-danger-text;
    }
    .has-success .form-control,
    .has-error .form-control {
        box-shadow: 0 0 0 white;
    }

    textarea {
        height: 100px!important;
    }
}

.multiselect-container > li > a > label {
    padding-left: 0;
}

// Brick form
.pimcore_area_ExteraForm {
    margin: 0 -15px;

    form {
        @extend .form-horizontal;
        background-color: transparent!important;
    }

    .form-group {
        margin-left: 0!important;
        margin-right: 0!important;
        //clear: left;
    }

    /*.form-control {
        padding: .5em .5em 0.25em;
    	width: 100%;
    	background: $grey-light;
    	//color: #9da8b2;
    	//font-size: 1.25em;
    }*/

    textarea {
        height: 80px!important;
    }

    /*.btn {
        @extend .btn--rounded;
    }*/

    .contact-acceptance.checkbox {
        label {
            display: none;
        }

        input[type=checkbox] {
            margin-left: 0;
        }

        .hint {
            padding-left: 25px;
        }
    }

    .style-select:before {
        z-index: 2;
    }

    form .control-label {
        text-align: left;
        display: block;
        margin-bottom: 10px;
    }

    input[type=checkbox].form-control {
        display: inline-block;
        width: auto;
        margin: 0;
        padding: 0;
        margin-right: 5px;
        margin-bottom: 10px;
    }
}

// Fullscreen window from SCM group
.win-fullscreen {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    pointer-events: none;
    opacity: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    transition: opacity .5s ease-in-out 0s;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);

    &:after,
    &:before {
        content: '';
        position: absolute;
        pointer-events: none;

        @supports (-webkit-appearance:none) {
            width: calc(100% + 15px);
            height: calc(100% + 15px);
            border: 1.5em solid rgba($secondary-color,.85);
        }

        transform: translate3d(0, 0, 0);

        @media (max-width: 1024px){
            display: none;
        }
    }

    &--open {
        pointer-events: auto;
        opacity: 1;
        overflow: hidden;
    }

    .close-icon {
        position: absolute;
        top: 1.7em;
        right: 1.7em;
        cursor: pointer;
        width: 40px;
        height: 40px;

        @media (max-width: 767px){
            top: 15px;
            right: 15px;
            width: 20px;
            height: 20px;
        }

        &:hover {
            opacity: .8;
        }

        &:after,
        &:before {
            background-color: $secondary-color;
        }
    }
}

.win-fullscreen-search,
.win-fullscreen-lang {
    @extend .win-fullscreen;
}

.win-fullscreen-search {

    &__form {
        width: 70%;
        margin: 5em 0;

        opacity: 0;
        transition: opacity 0.5s, transform 0.5s;

        .win-is-open & {
            opacity: 1;
            transform: scale3d(1, 1, 1);
        }
    }

    &__input {
        font-family: inherit;
        font-size: 5vw;
        line-height: 1;
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
        height: auto!important;
        padding: 0.05em 0;
        color: $primary-color;
        border: none;
        border-bottom: 2px solid;
        outline: 0;
    }

    &__info {
        font-size: 90%;
        font-weight: bold;
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 0.85em 0;
        text-align: right;
        color: $primary-color;
    }

    &__related {
        display: flex;
        width: 70%;
        pointer-events: none;
        color: $primary-color;

        /*.with-lang & {
            display: inline-block;
        }*/
    }

    &__suggestion {
        width: 33.33%;
        text-align: left;

        @media (max-width: 480px) {
            width: 100%;
        }

        &:nth-child(2){
            margin: 0 3em;
        }

        .h3 {
            font-size: 1.35em;
            margin: 0;
        }

        .h3::before {
            content: '\21FE';
            display: inline-block;
            padding: 0 0.5em 0 0;
        }

        p {
            font-size: 1.15em;
            line-height: 1.4;
            margin: 0.75em 0 0 0;
        }
    }
}

.win-fullscreen-search__input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    opacity: 0.3;
    color: $grey-border;
}

.win-fullscreen-search__input::-moz-placeholder {
    opacity: 0.3;
    /* Mozilla Firefox 19+ */
    color: $grey-border;
}

.win-fullscreen-search__input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 0.3;
    color: $grey-border;
}

.win-fullscreen-search__input::-webkit-search-cancel-button,
.win-fullscreen-search__input::-webkit-search-decoration {
    -webkit-appearance: none;
}

.win-search__input::-ms-clear {
    display: none;
}

.win-fullscreen-lang {
    //background-color: $white;
    top: auto;
    bottom: 0;

    &__related {
        //display: flex;
        max-width: 760px;
        color: $primary-color;

        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

///////
// With form per scheda prodotto
.form-fullscreen {
    @extend .win-fullscreen;

    text-align: left;
    //background-color: $white;

    &.is-open {
        pointer-events: auto;
        opacity: 1;
        overflow: hidden;
    }

    /*.close-icon {
        z-index: 50;
        font-size: 20px;
    }*/

    @media (max-width: 767px){
        .form-wrap form > .row,
        .form-wrap > .row {
            margin: 0 -15px;
        }

        .form-wrap .head-form {
            margin-top: 15px;
        }
    }

}

.ajax-form {
    position: relative;
    margin-top: 15px;

    .alert {
        display: none;
        margin-top: 30px;
    }

    &.form--done,
    &.form--success {
        .alert-success {
            display: block;
        }
    }

    &.form--error {
        .alert-danger {
            display: block;
        }
    }

}

form {
    .multiselect-button-container {
        height: 110px;
        overflow: hidden;
        border-bottom: 1px solid #d0d0d0;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;

        .multiselect-container {
            height: 110px!important;
        }
    }
}

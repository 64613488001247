
.tooltip {
    
    .tooltip-inner {
        padding: 10px 15px;
        background-color: white;
        color: #333;
        box-shadow: 0 0 5px #ccc;
    }
    .tooltip-arrow {
        border-top-color: white!important;
    }
}

.mobile-video {
    display: none;

    .touch & {
        display: block;
    }

    &__cont {
        position: relative;
        text-align: center;
        cursor: pointer;

        .fa {
            position: absolute;
            top: 70%;
            left: 0;
            right: 0;

            color: white;
            font-size: 40px;

            @media (min-width: 767px){
                font-size: 60px;
            }
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.footer-holder{
	width:100%;
	overflow:hidden;
	background:#e5e5e5;
}

#footer{
	max-width: 1148px;
	margin:0 auto;
	overflow:hidden;
	padding:19px 0;

	.logo{
		display: inline-block;
		overflow: hidden;
		margin: 0 0 13px;
		margin-top: 5px;
	}

	.copy-cont{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 15px;

        @media (min-width: 992px){
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }

        ul {
            margin: 0;
            padding: 0px;

            display: flex;
            gap: 10px;
        }

        ul li {
            list-style: none;
            display: inline-block;
            margin-left: 0px;
        }
    }
    
    .footer-top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .btn {
            max-width: 300px;

            &:hover {
                border-color: white!important;
                color: white!important;
            }
        }
    }
}

.footer-menus {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;

    @media (min-width: 768px){
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__list {
        display: flex;
        //flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;

        @media (min-width: 768px){
            //flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .btn {
        max-width: 300px;
    }

    ul {
        margin: 0;
        padding: 0;
        //font-size: 18px;
        //margin-right: 1rem;

        @media (min-width: 768px){
            min-width: 200px;
            max-width: 210px;
        }

        li {
            list-style: none;

            > strong,
            > a {
                font-size: 16px!important;
            }

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
}

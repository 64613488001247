/*
Item name: menuscm - Responsive Megamenu
Author: marcoarib - marco.arib@gmail.com
License: http://codecanyon.net/licenses
*/

/*
	CONTENTS:

	 1 - MENU CONTAINER
		- Scrollable menu
	 2 - BRAND
	 3 - MENU
		- Right alignment
		- Menu items
		- Active/hover state
	 4 - DROPDOWN
		- Dropdown items
		- Hover state
		- Dropdown left alignment
	 5 - DROPDOWN/MEGAMENU INDICATORS
	 6 - MEGAMENU
	 7 - MEGAMENU WIDTHS
		- Half width
		- Quarter width
	 8 - GRID SYSTEM
	 9 - SHOW/HIDE BUTTON (MOBILE MODE)
		- Icon
	10 - ICONS (parent menu items)
	11 - SEARCH FIELD
		- Form
		- Input
	12 - VERTICAL TABS
	13 - COLOR SCHEMES
	14 - MOBILE MODE (RESPONSIVE MODE)
		- Menu container
		- Brand
		- Show/hide button
		- Menu
		- Dropdown
		- Dropdown items
		- Dropdown/megamenu indicators
		- Megamenu
		- Search field
*/

/* FONT
======================================*/

// BEM VERSION - Extera - Enea

.primary-menu {
	min-width: 700px;

	@media (max-width: 767px){
		min-width: inherit;
	}

	&__menu {

	}

	&__li {
		> a {
			color: $default;

			-o-transition: color .25s ease-in-out;
			-moz-transition: color .25s ease-in-out;
			-webkit-transition: color .25s ease-in-out;
			transition: color .25s ease-in-out;

			@media (min-width: 768px){
				&.active,
				&:active,
				&:hover {
					color: rgba($default,.6)!important;
					//color: black!important;
				}
			}

			/*&.active {
				text-decoration: underline;
			}*/
		}
	}

	.showhide {
		padding: 0;
		width: 90px;
		height: auto;
		cursor: pointer;
		position: absolute;
		top: -50px;
		right: 0;
		color: $default;

		&:before {
			content: 'MENU';
			position: absolute;
			top: 2px;
			left: 0;
		}

		em {
			margin: 3.5px 10px 0;
		}
    }

	&__megamenu {
		.show-all {

			@media (max-width: 767px){
				display: none;
			}

		}
	}
}


//////

/* 1 - MENU CONTAINER
======================================*/
.menuscm,
.menuscm *{
	//font-family: "HelveticaNeue", Helvetica, Arial, sans-serif;
	font-family: "Helvetica", Arial, sans-serif;
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
}

.menuscm .fa { font-family:'FontAwesome'!important; }


.menuscm {
	//width: 100%;
	min-width: 600px;
	padding: 0 30px;
	padding-right: 0;
	float: right;
	position: relative;
	//margin-top: -25px;

	.search{
		.fa{
			padding: 33px 16px;
			padding-right: 0;
		}

		.toggle-form{
			cursor: pointer;
		}
	}

	.search-is-visible & {

	}

}
.menuscm a{
	-o-transition: color .1s linear, background .1s linear;
	-moz-transition: color .1s linear, background .1s linear;
	-webkit-transition: color .1s linear, background .1s linear;
	transition: color .1s linear, background .1s linear;
}
/* scrollable menu */
.menuscm-menu.scrollable > .scrollable-fix{
	display: none;
}

/* 2 - BRAND
======================================*/
.menuscm-brand{
	margin: 18px 30px 0 0;
	float: left;
	color: #666;
	text-decoration: none;
	font-size: 24px;
	font-weight: 600;
	line-height: 1.3;
	cursor: pointer;
}

/* 3 - MENU
======================================*/
.menuscm-menu{
	margin: 0;
	padding: 0;
	float: right;
	list-style: none;

	max-height: inherit;
}
/* Right alignment */
.menuscm-menu.menuscm-right{
	float: right;
}
/* Menu items */
.menuscm-menu > li{
	display: inline-block;
	float: left;
}
.menuscm-menu > li > a {
	padding: 30px 16px;
	display: inline-block;
	text-decoration: none;
	font-size: 15px;
	font-weight: 400;
	outline: 0;
	line-height: 1.42857143;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;

	text-transform: uppercase;

	.a-landing & {
		color: #333!important;
	}

	&[href*="contacts"] {
		background-color: $bluscm;
		color: white;

		.indicator {
			color: white;
		}
		&+ul {
			background-color: $bluscm!important;

			li > a {
				color: white;
			}
		}
	}
}
/* Active/hover state (Menu items) */
/*.menuscm-menu > li.active > a,
.menuscm-menu > li:hover > a{
	color: $default;
}*/

/* 4 - DROPDOWN
======================================*/
.menuscm-menu ul.dropdown,
.menuscm-menu ul.dropdown li ul.dropdown{
	list-style: none;
    margin: 0;
    padding: 0;
	display: none;
    position: absolute;
    z-index: 99;
	//min-width: 180px;
	min-width: 210px;
	white-space: nowrap;
	background: #fff;
}

.menuscm-menu ul.dropdown { padding: 15px 0; }

.menuscm-menu ul.dropdown li ul.dropdown{
    left: 100%;
}

/* Dropdown items */
.menuscm-menu ul.dropdown li{
	clear: both;
	width: 100%;
	border: 0 none;
	font-size: 12px;
	position: relative;
}
.menuscm-menu ul.dropdown li a {
	width: 100%;
	background: none;
	padding: 5px 15px;
  	padding-right: 25px;
	color: #666;
	text-decoration: none;
	display: inline-block;
	float: left;
	clear: both;
	position: relative;
	outline: 0;
	font-size: 14px;

	@include single-transition(color,.05s,linear,0s);

	&::after{
		content:"\e60a";
    font-family: 'icomoon';
    float: right;
    color: #eee;
    position: absolute;
    right: 10px;

		@include single-transition(color,.05s,linear,0s);
	}
}
/* Hover state (dropdown items) */
.menuscm-menu ul.dropdown li:hover > a{
	color: #333;

	&::after{
		color: #333
	}
}

/* Dropdown left alignment */
.menuscm-menu ul.dropdown.dropdown-left{
	left: none;
}
.menuscm-menu ul.dropdown li ul.dropdown.dropdown-left{
	left: -100%;
}

/* 5 - DROPDOWN/MEGAMENU INDICATORS
======================================*/
.menuscm-menu li .indicator{
	margin-left: 3px;
	display: none;
}
.menuscm-menu li ul.dropdown li .indicator{
	position: absolute;
	top: 8px;
	right: 10px;
	font-size: 15px;
}

/* 6 - MEGAMENU
======================================*/
.menuscm-menu > li > .megamenu {
	//width: 650px;
    width: auto;
	padding: 20px;
    padding-left: 0;
	position: absolute;
	display: none;
	z-index: 99;
	background: #fff;
	right: auto;
	margin-left: -74px;
	text-align: left;
    min-width: 300px;

    @media (max-width: 800px) and (min-width: 768px) {
        margin-left: -165px;
    }

    &.submenu-open{
        width: 600px;
    }

    .special-lang & {
        margin-left: -78px;
    }

	.s-www_scmgroup_ru & {
		margin-left: -101px;
	}
}

/* 7 - MEGAMENU WIDTHS
======================================*/
/* Half width */
.menuscm-menu > li > .megamenu.megamenu-half-width{
	width: 50%;
	left: auto;
}
/* Quarter width */
.menuscm-menu > li > .megamenu.megamenu-quarter-width{
	width: 25%;
	left: auto;
}

/* 8 - GRID SYSTEM
======================================*/
.menuscm-menu > li > .megamenu .megamenu-row{
	width: 100%;
	margin-top: 15px;
}
.menuscm-menu > li > .megamenu .megamenu-row:first-child {
	margin-top: 0;
}
.menuscm-menu > li > .megamenu .megamenu-row:before,
.menuscm-menu > li > .megamenu .megamenu-row:after {
	display: table;
	content: "";
	line-height: 0;
}
.menuscm-menu > li > .megamenu .megamenu-row:after {
	clear: both;
}
.menuscm-menu > li > .megamenu .megamenu-row .col1,
.menuscm-menu > li > .megamenu .megamenu-row .col2,
.menuscm-menu > li > .megamenu .megamenu-row .col3,
.menuscm-menu > li > .megamenu .megamenu-row .col4,
.menuscm-menu > li > .megamenu .megamenu-row .col5,
.menuscm-menu > li > .megamenu .megamenu-row .col6,
.menuscm-menu > li > .megamenu .megamenu-row .col7,
.menuscm-menu > li > .megamenu .megamenu-row .col8,
.menuscm-menu > li > .megamenu .megamenu-row .col9,
.menuscm-menu > li > .megamenu .megamenu-row .col10,
.menuscm-menu > li > .megamenu .megamenu-row .col11,
.menuscm-menu > li > .megamenu .megamenu-row .col12{
	display: block;
	min-height: 20px;
	float: left;
	margin-left: 3%;
}
.menuscm-menu > li > .megamenu .megamenu-row [class*="col"]:first-child {
	margin-left: 0;
}
.menuscm-menu > li > .megamenu .megamenu-row .col1{
	width: 5.583333333333%;
}
.menuscm-menu > li > .megamenu .megamenu-row .col2{
	width: 14.166666666666%;
}
.menuscm-menu > li > .megamenu .megamenu-row .col3{
	width: 22.75%;
}
.menuscm-menu > li > .megamenu .megamenu-row .col4{
	width: 31.333333333333%;
}
.menuscm-menu > li > .megamenu .megamenu-row .col5{
	width: 39.916666666667%;
}
.menuscm-menu > li > .megamenu .megamenu-row .col6{
	width: 48.5%;
}
.menuscm-menu > li > .megamenu .megamenu-row .col7{
	width: 57.083333333333%;
}
.menuscm-menu > li > .megamenu .megamenu-row .col8{
	width: 65.666666666667%;
}
.menuscm-menu > li > .megamenu .megamenu-row .col9{
	width: 74.25%;
}
.menuscm-menu > li > .megamenu .megamenu-row .col10{
	width: 82.833333333334%;
}
.menuscm-menu > li > .megamenu .megamenu-row .col11{
	width: 91.416666666667%;
}
.menuscm-menu > li > .megamenu .megamenu-row .col12{
	width: 100%;
}

/* 9 - SHOW/HIDE BUTTON (MOBILE MODE)
======================================*/
.menuscm .showhide{
	//padding: 13px 0 0;
	display: none;
	float: right;
	text-decoration: none;
	outline: none;

	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
}
/* Show/hide button icon */
.menuscm .showhide em {
	width: 20px;
	height: 3px;
	margin: 3.5px 20px 0;
	float: right;
	background: $default;
}

/* 10 - ICONS (parent menu items)
======================================*/
.menuscm-menu > li > a > i{
	margin: 0 5px 0 0;
	font-size: 18px;
	float: left;
	line-height: 20px;
	font-family: 'FontAwesome';
}

/* 11 - SEARCH FIELD
======================================*/
/* Form */
.menuscm-menu > li.search form{
	float: left;
	padding: 22px 0 17px;
	opacity: 0;

	position: absolute;
	top: 0;
	right: -10px;

	z-index: -1;

	-o-transition: opacity .2s ease-out;
	-moz-transition: opacity .2s ease-out;
	-webkit-transition: opacity .2s ease-out;
	transition: opacity .2s ease-out;

	width: 100%;

	.search-is-visible & {
		opacity: 1;
		z-index: 1;
	}

	.fa-close{
	  position: absolute;
	  z-index: 3;
	  top: -3px;
	  right: 10px;
	  cursor: pointer;
	  font-size: 20px;
	}
}

/* Input */
.menuscm-menu > li.search form input[type="text"]{
	width: 100%;
	height: 37px;
	padding: 0 10px;
	//font-family: "HelveticaNeue", Helvetica, Arial, sans-serif;
	font-family: "Helvetica", Arial, sans-serif;
	border: none;
	background: #fff;
	outline: 0;
}

/* 12 - VERTICAL TABS
======================================*/
.menuscm-tabs{
	width: 100%;
	float: left;
}
.menuscm-tabs-nav{
	margin: 0;
	padding: 0 20px 0 20px;
	float: left;
	list-style: none;
	border-right: 1px solid #cccccc;
	//width: 310px;
}
.menuscm-tabs-nav > li > a {
	width: 100%;
	padding: 7px 16px;
	float: left;
	font-size: 13px;
	text-decoration: none;
	color: #666;
	outline: 0;
	text-align: right;
}

.menuscm-tabs-nav > li:hover > a {
	background: #f0f0f0;
	color: #333;
}

.menuscm-tabs-nav li.active a,
.menuscm-tabs-nav li:hover a{
	/*background: #f0f0f0;
	color: #333;*/
}
.menuscm-tabs-content{
	min-height: 30px;
	float: right;
	opacity: 0;
  	z-index: -1;
  	visibility: hidden;
  	font-size: 13px;
	padding: 0 20px;
	position: absolute;
	left: 283px;
	min-width: 315px;
	max-width: 317px;

	ul{
		margin: 0;
		padding: 0;
		list-style: none;

		li{
			float: left;
			padding-right: 0;
			width: 100%;
			a{
			}
		}
	}
}
.menuscm-tabs-content.active{
	//display: block;

    z-index: 1;
    opacity: 1;
    visibility: visible;
}

/* 13 - COLOR SCHEMES
======================================*/
/* blue */
.menuscm.blue .menuscm-menu > li.active > a,
.menuscm.blue .menuscm-menu > li:hover > a,
.menuscm.blue .menuscm-menu ul.dropdown li:hover > a{
	color: #4A89DC;
}
/* green */
.menuscm.green .menuscm-menu > li.active > a,
.menuscm.green .menuscm-menu > li:hover > a,
.menuscm.green .menuscm-menu ul.dropdown li:hover > a{
	color: #37BC9B;
}
/* red */
.menuscm.red .menuscm-menu > li.active > a,
.menuscm.red .menuscm-menu > li:hover > a,
.menuscm.red .menuscm-menu ul.dropdown li:hover > a{
	color: #E9573F;
}
/* orange */
.menuscm.orange .menuscm-menu > li.active > a,
.menuscm.orange .menuscm-menu > li:hover > a,
.menuscm.orange .menuscm-menu ul.dropdown li:hover > a{
	color: #ff670f;
}
/* yellow */
.menuscm.yellow .menuscm-menu > li.active > a,
.menuscm.yellow .menuscm-menu > li:hover > a,
.menuscm.yellow .menuscm-menu ul.dropdown li:hover > a{
	color: #F6BB42;
}
/* purple */
.menuscm.purple .menuscm-menu > li.active > a,
.menuscm.purple .menuscm-menu > li:hover > a,
.menuscm.purple .menuscm-menu ul.dropdown li:hover > a{
	color: #967ADC;
}
/* pink */
.menuscm.pink .menuscm-menu > li.active > a,
.menuscm.pink .menuscm-menu > li:hover > a,
.menuscm.pink .menuscm-menu ul.dropdown li:hover > a{
	color: #D770AD;
}

/*integrazione con i nostri */
.menuscm-menu ul.submenu{
	margin: 0;
	padding: 12px 20px 12px 0;
	float: left;
	list-style: none;
	width: 100%;
    padding-right: 0;
}
 .menuscm-menu ul.logos{
 	padding-bottom: 12px;
 	display: inline-block;
 	width: 100%;

 	li{
 		float: left;
 		width: auto;
 		padding-right: 10px;
 	}
 }

 .menuscm-menu ul.submenu li{
 	width: 100%;
 	clear: left;
 }
 .menuscm-menu ul.submenu > li > a{
	width: 100%;
	padding: 7px 0;
	float: left;
	font-size: 13px;
	text-decoration: none;
	color: #666;
	outline: 0;

	display: block;
	//padding: 7px 16px;
 }

.menuscm-menu ul.submenu > li:hover > a{
	/*background-color: #f0f0f0;
	color: #333;*/
}

/* 14 - MOBILE MODE (RESPONSIVE MODE)
======================================*/
@media (max-width: 767px){
	/* Responsive menu container */
	.menuscm-responsive.menuscm{
		padding: 0;
	}

	/* Brand */
	.menuscm-responsive .menuscm-brand{
		margin: 8px 30px 10px 20px;
		font-size: 20px;
		line-height: 1.55;
	}

	/* Show/hide button */
	.menuscm-responsive .showhide {
		margin: 0;
	}

	/* Menu */
	.menuscm-responsive .menuscm-menu{
		width: 100%;
		float: left !important;
	}
	/* scrollable menu*/
	.menuscm-responsive .menuscm-menu.scrollable{
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
	.menuscm-responsive .menuscm-menu.scrollable > .scrollable-fix{
		display: block;
	}

	/* Menu items */
	.menuscm-responsive .menuscm-menu > li{
		width: 100%;
		display: block;
		position: relative;
	}
	.menuscm-responsive .menuscm-menu > li > a{
		width: 100%;
		padding: 12px 15px;
		display: block;
		border-bottom: solid 1px #f0f0f0;
	}
	.menuscm-responsive .menuscm-menu > li:first-child > a{
		border-top: solid 1px #f0f0f0;
	}

	/* Dropdown */
	.menuscm-responsive .menuscm-menu ul.dropdown,
	.menuscm-responsive .menuscm-menu ul.dropdown li ul.dropdown{
		width: 100%;
		left: 0;
		position: static;
		border: none;
		background: #fff;
		float: left;
	}

	/* Dropdown items */
	.menuscm-responsive .menuscm-menu ul.dropdown li{
		position: relative;
	}
	.menuscm-responsive .menuscm-menu ul.dropdown li a{
		border-bottom: solid 1px #f0f0f0;
	}
	//.menuscm-responsive .menuscm-menu.menuscm-indented > li > ul.dropdown > li > a{ padding-left: 10px !important; }
	.menuscm-responsive .menuscm-menu.menuscm-indented > li > ul.dropdown > li > ul.dropdown > li > a{ padding-left: 20px !important; }
	.menuscm-responsive .menuscm-menu.menuscm-indented > li > ul.dropdown > li > ul.dropdown > li > ul.dropdown > li > a{ padding-left: 60px !important; }
	//.menuscm-responsive .menuscm-menu.menuscm-indented > li > ul.dropdown > li:hover > a{ padding-left: 10px !important; }
	.menuscm-responsive .menuscm-menu.menuscm-indented > li > ul.dropdown > li > ul.dropdown > li:hover > a{ padding-left: 20px !important; }
	.menuscm-responsive .menuscm-menu.menuscm-indented > li > ul.dropdown > li > ul.dropdown > li > ul.dropdown > li:hover > a{ padding-left: 60px !important; }

	/* Dropdown/megamenu indicators */
	.menuscm-responsive .menuscm-menu li .indicator{
		width: 60px;
		height: 45px;
		position: absolute;
		right: 0;
		top: 0;
		font-size: 22px;
		text-align: center;
		line-height: 43px;
		border-left: solid 1px #f5f5f5;
	}
	.menuscm-responsive .menuscm-menu li ul.dropdown li .indicator{
		height: 38px;
		right: 0;
		top: 0;
		font-size: 18px;
		line-height: 36px;
	}

	/* Megamenu */
	.menuscm-responsive .menuscm-menu > li > .megamenu{
		width: 100% !important;
		left: 0 !important;
		position: static;
		border-top: none;
		/*border-bottom: solid 1px #f0f0f0;*/
	}
	.menuscm-responsive .menuscm-menu > li > .megamenu .megamenu-row [class*="col"]{
		float: none;
		display: block;
		width: 100% !important;
		margin-left: 0;
		margin-top: 15px;
	}
	.menuscm-responsive .menuscm-menu > li > .megamenu .megamenu-row:first-child  [class*="col"]:first-child {
		margin-top: 0;
	}
	.menuscm-responsive .menuscm-menu > li > .megamenu .megamenu-row{
		margin-top: 0;
	}

	/* Search field */
	.menuscm-responsive .menuscm-menu > li.search form {
		width: 100%;
		margin: 0;
		padding: 0 16px !important;
		height: 40px;
	}
	.menuscm-responsive .menuscm-menu > li.search form input[type="text"]{
		width: 100%;
		height: 40px;
	}

	/* Tabs */
	.menuscm-responsive .menuscm-tabs-nav{
		width: 100%;
	}
	.menuscm-responsive .menuscm-tabs-content{
		width: 100%;
	}

	.menuscm {
    min-width: inherit;
    margin-top: 0;
    width: 100%;
    float: right;

    .toggle-form-mob {
      position: absolute;
      top: 7px;
      right: 50px;
    }

	// From extera.scss
    .menuscm-menu {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      z-index: 100;
      border-bottom: 2px solid $grey-default;

      .scroll & {
        top: 80px;
      }

      .liprodotto {
        text-align: left;
      }

      .indicator {
        display: block;
      }

      li:first-child {
        .indicator {
          //display: none;
        }
      }

      .dropdown {
        padding: 0;

        li > a {
          padding: 12px 15px!important;
          text-transform: uppercase;

          &::after {
            display: none;
          }
        }

        li:hover {
          a {
            padding-left: 10px !important;
          }
        }
      }

      .megamenu {
        margin-left: 0;
        float: left;
        width: 100%;
        padding: 0;
      }

      .menuscm-tabs-nav {
        padding: 0;

        li {
          float: left;
          border-bottom: 1px solid #f0f0f0;
          width: 100%;

          a {
            padding: 12px 15px;
            text-transform: uppercase;
            text-align: left;
            display: block;

            &::before {
              display: none;
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .menuscm-tabs-content {
        display: none;
      }
    }
  }
}

/* Modifiche Simon */
.menuscm-tabs-nav > li:hover a {
	background-color: transparent!important;
}

.menuscm-menu ul.submenu {
	clear: both;
}

.menuscm-tabs-nav {
    //width: 340px;
    border-right:none;
}

.menuscm-tabs-nav li a{
    padding: 5px 15px;
    font-size: 14px;
    //color: #888;
}
.menuscm-tabs-nav li a:hover{
    color: #333;
}

.menuscm-menu ul.submenu > li > a{
    padding: 5px 0;
    font-size: 14px;
    //color: #333;
}
.menuscm-menu ul.submenu > li > a:hover{text-decoration: underline;}

.menuscm a {
  -o-transition: color .05s linear, background .05s linear;
  -moz-transition: color .05s linear, background .05s linear;
  -webkit-transition: color .05s linear, background .05s linear;
  transition: color .05s linear, background .05s linear;
}

.menuscm-tabs-nav > li > a:before{
    content:"\e60a";
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    display: block;
    float: right;
    margin-right: -30px;
    color: #eee;
}

.menuscm-tabs-nav > li:hover > a:before{
    color: #333;
}

.menuscm .title{
    font-size: 28px;
    color: #333;
}

.menuscm .title,
.menuscm .title2{
	font-weight: 200;
    //font-family: 'HelveticaNeueThin', Helvetica, Arial, sans-serif;
	font-family: "Helvetica", Arial, sans-serif;
    font-size: 25px;
    line-height: 1.1;
    letter-spacing: -.02em;
    text-align: left;
    margin-bottom: 12px;
    color: #333;
    clear: both;
    display: block;
}


.menuscm-tabs-content a.item {
	clear: left;
	color: #333;
	font-weight: bold;
	padding: 0;

	@include single-transition(color,.25s,ease,0s);

    display: block;
    padding-right: 20px;
    position: relative;
}

.menuscm-tabs-content a.item::after{
    content:"\e60a";
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;

    position: absolute;
    top: 2px;
    right: 10px;

    @include single-transition(right,.25s,ease,0s);
}
.menuscm-tabs-content a.item:hover::after{
	text-decoration: none;

    right: 8px;
}

.menuscm-tabs-content a.item:hover {
	text-decoration: none;
	color: $default;
}

/* voci di menu menu indent */
.menuscm-menu ul.dropdown li {

	a,
	span {

		&.indent-label {
        text-transform: uppercase;
        font-size: 95%;
        margin-bottom: -2px;
        font-weight: bold;
        color: $default;

	        &:hover {
	            color: $default;
	            cursor: default;
	        }
	        &::after{
	            display: none
	        }
	    }

	    &.indent-item {
	        text-indent: 1em;
	    }

	}
}

#sidebar .navigation > li {

    a,
    span {
    	&.indent-label {
	        text-transform: uppercase;
	        font-size: 90%;
	        margin-bottom: -10px;
	        font-weight: bold;
	        margin-top: 10px;

	        &:hover {
	            color: $default;
	            cursor: default;
	        }
	        &::before{
	            display: none
	        }
	    }
	    &.indent-item {
	        //text-indent: 1em;
            padding: 0 10px;
	    }
    }
}

/* Allineamento menu */
.liprodotto {
	width: 180px;
	text-align: right;

    @media (max-width: 800px) and (min-width: 768px) {
        width: auto;
    }

}

#menuscm .megamenu,
#menuscm .dropdown {
    top: 100%;
}


@media (max-width: 1024px){

    .special-lang .menuscm-menu {
        padding: 0;

        .dropdown::before,
        .megamenu::before{
            top: -20px;
        }

        > li > a {
            font-size: 14px;
            padding: 30px 10px;
        }
    }

}


@media (max-width: 800px) and (min-width: 768px) {

	/*.menuscm-menu > li > a { font-size: 13px; }*/

	.cont-filiali .list-logos{
		display: none;
	}

}

@media (min-width: 768px){
	.menuscm-menu .dropdown,
	.megamenu{
		position: relative;
		box-shadow: 0 0 21px rgba(0, 0, 0, 0.2);

		&::before{
			content:'';
			display:block;
			position:absolute;
			top: -21px;
			left: -12px;
			background-color:#fff;
			width: 105%;
			height:21px;
			z-index: 100;
		}
	}

	.menuscm-menu .dropdown{
		&::before{
			width:110%;
			left: -10px;
		}
	}

    .menuscm-tabs{
        width: 273px;

        .special-lang & {
            width: 300px;
        }
    }

    .liprodotto > a {
        padding-left: 0!important;
    }
}

.menuscm .menuscm-tabs .menuscm-tabs-nav .show-all {
  font-weight: bold;
  a {
    color: $default;

    &::before {
      content: '';
    }
  }
}

.menuscm .menuscm-tabs .menuscm-tabs-nav .item.primo {
  margin-bottom: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .menuscm {
    min-width: inherit;

    .menuscm-menu {
      li.item-menu > a {
        padding: 30px 10px;
      }

      .item-menu:last-child .dropdown {
        right: 0;
      }
    }
  }
}


#content {
    .a-contact & {
        @media (min-width: 992px){
            padding-top: 0;
        }
    }
}

#request-info {
    .a-contact & {
        .col-sm-4.field-block {
            width: 100%;

            @media (min-width: 767px){
                width: 50%;
            }

            .multiselect-button-container {
                height: 110px;
                overflow: hidden;
                border-bottom: 1px solid #d0d0d0;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;

                .multiselect-container {
                    height: 110px!important;
                }
            }
        }
        .col-sm-8.field-block {
            width: 100%;
        }
    }
}

.ext-content {
    &__item--end {
        display: flex;
        justify-content: flex-end;
    }
}
table.products-grid.table{

    border: none;
    background-color:#fff;
    min-width: 960px;
    border-collapse: separate;

    img{
        vertical-align: middle;
    }

    /* dimensioni */
    th.c0{width:22%;}
    td.c0{width:13%;}
    td.c1{width:13%;}
    td.c2{width:13%;}
    td.c3{width:13%;}
    td.c4{width:13%;}
    td.c5{width:13%;}
    td.c6{width:13%;}

    .prima .empty,
    .seconda .empty,
    th.c0,
    td.c0,
    td.c4{
        border-right: 5px solid #fff;
    }

    .prima th:nth-child(2),
    .seconda th:nth-child(4) {
        border-right: 5px solid #fff;
    }

    /* intestazioni */
    thead tr th{ text-align: center; padding: 5px; vertical-align: middle; border-width: 0px }

    /* tipografia */
    tbody tr.row-category td{ text-align: left; vertical-align: middle; }
    tr.row-category th{ font-weight: normal; background-color: #c9c9c9; }

    thead tr.prima th{ font-size: 28px; font-weight: normal;}
    thead tr.seconda th{ font-weight: normal; border-bottom-width: 5px; color: #444; }

    /* colori */
    thead tr th{background-color: #e1e1e1; color: $primary-color;}
    thead tr th.empty{background-color: transparent;}

    .products-grid{ background-color: #fff!important;}
    tbody tr.row-category td{ background-color: #c9c9c9;}
    tbody tr.row-type td{ background-color: #e1e1e1;}
    tbody td.c0{background-color: transparent;}
    tbody tr.row-category td.empty,
    tbody tr.row-type td.empty{ background-color: transparent;}

    /* bordi */
    & > thead > tr > th,
    & > thead > tr > td,
    & > tbody > tr > th,
    & > tbody > tr > td,
    & > tfoot > tr > th,
    & > tfoot > tr > td{border-color: #fff;}

    td.c3,
    td.c5{border-right:none;}

    td.c4,
    td.c6{border-left:none;}

    /* link */
    ul{ margin: 0;
        padding: 4px 0 0 0;
        list-style: none;}
    ul li{
        line-height: 1.1em;
        margin-bottom: .5em;
        font-size: 12px;
    }
    a{ color: #444; text-decoration: none;}
    a:hover{ color: #444; text-decoration: underline;}

    /* apri e chiudi */
    tr.row-category {
        th {
            position: relative;
            padding-left: 1.8em;
        }
        a.type-toggle {
            position: absolute;
            top: .6em;
            left: .6em;
        }
    }

    tbody {
        border: none;
    }

}

/* tooltip prodotto */



	.qtip-content{
		position: relative;
		padding: 5px 9px;
		overflow: hidden;

		text-align: left;
		word-wrap: break-word;
	}


/* Default tooltip style */
.qtip-product{
	border: 1px solid #888;
        border-radius: 1px;
	background-color: #fff;
	color: #444;

        .qtip-content {

            padding: 10px;
            font-size: 12px;

            h3 {
                font-size:150%;
                margin: 0;
            }

            h4 {
                font-size:130%;
                margin: .4em 0 0 0;
            }

            p {
                font-size:100%;
                line-height: 1.4em;
                margin: .4em 0 0 0;
            }

        }
}

.products-grid {
    .row-type,
    .row-category {
        td {
            border-right: 0;
            &.c2,&.c4 {
                border-right: 5px solid white;
            }

            &.with-colspan {
                padding-left: 110px;

                @media (max-width: 767px){
                    padding-left: 28px;
                }
            }
        }
    }
}

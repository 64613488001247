
// Promo
.promo {
    margin-bottom: 15px;

    &__prezzo { }

    &__img {
        background-color: #f0f0f0;
        min-height: 100px;
    }
}

.product__gallery {
    margin-top: 0;
}

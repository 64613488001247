
.site-sidebar {

    a {
        color: $base-text-color;

        &:hover {
            color: $base-text-color;
        }
    }

    ul {
        padding: 0;
    }

    li {
        list-style: none;
    }

    li > a {
        color: $base-text-color!important;
    }

    &__title {
        color: $secondary-color;
        margin-bottom: 30px;
        display: inline-block;

        &.color-default {
            color: $default;
        }
    }

    &__links {
        width: 100%;
        margin-bottom: 23px;

        li {
            margin-bottom: 8px;
            //width: 100%;
        }

        li > a {
            display: inline-block;
            width: 100%;
            position: relative;

            &:focus,
            &:active,
            &:hover {
                text-decoration: none;
            }
        }

        &.custom a {
            padding: 10px 10px 10px 35px;
            background-color: $grey-light;
            position: relative;
            color: $base-text-color;

            &:hover {
                background-color: $grey-hover;
            }
        }

        &.custom .fa {
            color: $secondary-color;
            font-size: 20px;
            position: absolute;
            left: 15px;
        }
    }

    &__search {
        margin-bottom: 20px;

        .input-cont,
        input[type=text] {
            width: 100%;
        }

        .input-cont {
            position: relative;
        }

        input[type=text]::-moz-placeholder,
        input[type=text]::-webkit-input-placeholder {
            font-weight: 300;
            font-size: 16px;
            color: $default;
        }

        .fa {
            position: absolute;
            right: 20px;
            bottom: 16px;
        }
    }

    &__menu {
        margin-bottom: 30px;
    }

    &__menu ul > li {
        padding-top: 14px;

        &:first-child {
            padding-top: 0;
        }
    }

    &__menu ul ul {
        padding-left: 15px;
        padding-top: 14px;
    }
}

@media (max-width: 767px){
    .site-sidebar  .filter_banner{
        display: none;
    }
}

// Sidebar in content page
#sidebar {

    .navigation > li {
        border: none;
        padding-top: 14px;

        &:first-child {
            border: none;
        }

        > a {
            padding: 0;

            &:hover {
                color: $default;
                text-decoration: underline;
            }
        }

        a:before {
            display: none;
        }
    }

}

// General

.g-superfici,
.g-scmcrif,
.g-casadei-busellato_com,
.g-casadeibusellato_com,
.g-elettronicasammarinese,
.g-csr {
    .menuscm-tabs-nav {
        float:right;
    }
    .menuscm-tabs-content {
        left:260px;
    }
}

.g-scm,
.g-cms {
    /* aggiunta iscrizione newsletter nel footer */
    #spot-nl-footer{
        color: $default;
        display: table;
        margin: 0 auto;

        h4 {
            font-size:110%;
            font-weight:600;
            margin-bottom: .4em;
            color: $bluscm;
            margin-top: 0;
        }

        input[type=text] {
            border: none;
            border-radius: 2px;
        }
    }
}


// Casadei busesllato
.g-casadei-busellato_com,
.g-casadeibusellato_com {

    /*.carousel-caption {
        p {
            &.slider-left {
                font-size: 20px;
            }
            &.titolo-slider {
                font-size: 30px;
            }
        }

        .btn {
            margin-top: 15px;
        }
    }*/

    .navbar {
        .navbar-brand img {
            width: auto;
            max-width: 300px;
            //margin-top: 14px;

            @media (max-width: 767px){
                max-width: 200px!important;
                //margin-top: 16px;
            }

            @media screen and (max-width: 991px){
                max-width: 250px!important;
            }
        }
    }
}

// Cms
.g-cms {

}

// Csr
.g-csr {

}


// Elettronica sammarinese
.g-elettronicasammarinese {

}

// Hiteco
.g-hiteco {

}

// Scm Group
.g-scm {
    //.top-panel .reserved-menu{margin-right:0;}

    /* fix form builder */
    .pimcore_area_ExteraForm {
        form label {
            margin-right: 10px;

            input[type="radio"]{
                display: inherit;
                width: 20px;
                height: inherit;
                box-shadow: none;
            }

            &.control-label {
                clear: both;
                display: block;
            }

            .form-group.show-br br {
                display:inherit;
            }

            .form-group br {
                display:none;
            }
        }

        form {
            #fieldset-Settoridiimpiego .form-group br {
                display:block;
            }
        }
    }

    /*
    &.s-www_scmtecmatic_com_br .navbar > .container-fluid .navbar-brand {
        margin-top: -3px;
    }*/

    /* short privacypolicy label */
    .nascondiPrivacyLabel {
        label[for*="_privacypolicy"] {
            display:none;
        }

        .font-small {
            font-size:80%;
        }
    }
}

// Criff
.g-scmcrif {

}

// Superfici
.g-superfici {

}

div.press-review {
  h3.subtitle-highlight {
    span {
      display: table-cell;
      height: 54px;
      vertical-align: middle;
    }
  }
  p.meta {
    font-size: 16px;
    text-align: right;
    font-style: italic;
  }
}
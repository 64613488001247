/*
███████╗██╗  ██╗████████╗███████╗██████╗  █████╗
██╔════╝╚██╗██╔╝╚══██╔══╝██╔════╝██╔══██╗██╔══██╗
█████╗   ╚███╔╝    ██║   █████╗  ██████╔╝███████║
██╔══╝   ██╔██╗    ██║   ██╔══╝  ██╔══██╗██╔══██║
███████╗██╔╝ ██╗   ██║   ███████╗██║  ██║██║  ██║
╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝
                                           © 1985

    Created on : 27-nov-2015, 17.06.43
    Author     : Lorenzo Nicoletti <lorenzo.nicoletti@extera.com>

    inspired from http://codepen.io/Rowno/pen/Afykb
    http://stackoverflow.com/questions/26770055/bootstrap-carousel-fade-no-longer-working-with-maxcdn-3-3-bootstrap-min-css

*/
.animation-box {
    border: 1px solid #ccc;
}

.specchietto .product-animation {
    display:inline-block;
    vertical-align:middle;
    text-align: center;
}

.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function:ease;

  &:hover {
      background-color: inherit;
  }
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*
WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
now override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
}


.site-content {
    &.padding-big {
        padding: 60px 0;
    }
    &.padding-small {
        padding: 30px 0;
    }

    &.padding-medium {
        padding: 40px 0;
    }

    &.padding-custom {
        padding-top: 30px;
        padding-bottom: 60px;
    }

    @media (max-width: 767px){
        &.padding-small,
        &.padding-medium,
        &.padding-big {
            padding: 30px 0;
        }
    }

    .btn.btn-more:after {
        display: none!important;
    }
}

// Read more
.read-more {
    max-height: 165px;
    overflow: hidden;
    position: relative;

    &.is-open {
        max-height: auto;
        padding-bottom: 50px;

        &:before {
            display: none;
        }
    }

    &:before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        //background: $white;
        background: linear-gradient(to bottom, rgba(255,255,255,0), $white 50%);
    }

    .btn {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}


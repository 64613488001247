
.carousel {
    &__item {
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }
    }

    &--single-image {

    }

    &--images {
        .item {
            background-color: black;
        }

        img {
            @include opacity(.8);
        }
    }

    &--big {
        //max-height: 583px;
        .carousel-caption {
            max-width: 960px;
        }

        .carousel-control .glyphicon:before {
            content: '';
            //font-family: 'HelveticaNeueThin', Helvetica, Arial, sans-serif;
            font-family: "Helvetica", Arial, sans-serif;
            text-rendering: optimizeLegibility;
            font-size: 50px;
            font-weight: 200;
            color: #fff;
        }

        .carousel-control .glyphicon-chevron-left:before {
            content: '\3008';
        }

        .carousel-control .glyphicon-chevron-right:before {
            content: '\3009';
        }
    }

}

.carousel-block{
	height: auto;
    //max-height: 583px;

	.item {
		//max-height: 583px;
	}

}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img{
	margin: auto;
}

.carousel-block{
	width:100%;
	overflow:hidden;

    /*img {
		width:100%;
		height:auto;
	}*/
}

.carousel-caption{
	//max-width: 1178px;
	left:0;
	right:0;
	bottom: 60px;
	overflow:hidden;
	text-align:center;
	padding:0 15px;
	margin:0 auto;
    font-size:26px;

    .btn {
        padding-right: 2em;
        &:after{
            content:'';
            position: absolute;
            right:15px;
            width:9px;
            height:9px;
            top:50%;
            margin-top:-5px;
            border:2px solid #fff;
            border-width:0 0 2px 2px;
            -moz-transform: rotate(225deg);
            -ms-transform: rotate(225deg);
            -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
            z-index: 10;
        }
    }
    p {
        max-width: 800px;
        clear: both;
        &.titolo-slider {
            line-height:58px;
            font-size:50px;

        }
        &.slider-left {
            float: left;
            text-align: left;
        }
        &.slider-center {
            margin:0 auto;
            text-align: center;
        }
        &.slider-right {
            float: right;
            text-align: right;
        }
    }
}
.editmode .carousel-caption p {
    &.slider-left {
        float: none;
    }
    &.slider-right {
        float: none;
    }
}

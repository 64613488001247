.mat {

    .s-prodlist__grid & {
        padding-top: 10px;
        border-top: 1px solid #ccc;
    }

    &__list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 0;
        padding-left: 0;

        .s-prodlist__grid & {
            position: relative;
            z-index: 30;
        }
    }

    &__item {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        margin: 0 5px;
        margin-bottom: 5px;
        overflow: hidden;

        .s-prodlist__grid & {
            width: 25px;
            height: 25px;
            margin: 0 2.5px;
            margin-bottom: 5px;
        }

        /*&:first-child {
            width: auto;
            height: auto;
            margin-left: 0;
            margin-right: 15px;

            .s-prodlist__grid & {
                font-size: 14px;
                margin-right: 10px;
            }
        }*/

        &:last-child {
            //margin-right: 0;
            .s-prodlist__grid & {
                margin-right: 0;    
            }
        }
    }

    &__img {
        object-fit: cover;
        margin-bottom: 0;
        height: 100%;
    }
}

.mat__label {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
}
/* head Opacity */

@media (min-width: 1024px) {
  /*#header {
    .video-is-played & {
      opacity: .4;
      @include single-transition(opacity, .3s, ease-in-out, .15s);

      &:hover {
        opacity: 1;
        @include single-transition(opacity, .15s, ease-in-out, 0s);
      }
    }
}*/
}

/* BIG VIDEO BACKGROUND
================================================== */
.big-background {
  z-index: 550;
  text-align: center;
  height: auto;
  position: relative;
  overflow: hidden;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  height: 70vh;
  min-height: 400px;

  .touch & {
    display: none;
  }

  .mb_YTVPMuteUnmute,
  .mb_OnlyYT,
  .mb_YTVPProgress,
  .mb_YTVPTime,
  .mb_YTVPUrl {
    display: none;
  }

  &.first-started {
    .mb_YTVPMuteUnmute, .mb_OnlyYT, .mb_YTVPProgress {
      opacity: .3;
      display: block;
      &:hover {
        @include single-transition(opacity, .25s, ease-in-out, 0s);
        opacity: 1;
      }
    }
  }

  .fullscreen {
    .mb_OnlyYT::before {
      content: '\f066'
    }
  }

  //@media screen and (min-width: 1025px) {

  //background-image: none!important;
  //}

  .big-background-container {
    width: 830px;
    max-width: 100%;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .editmode & {
      z-index: 2010;
    }

  }

  .mb_YTVPBar {
    position: static !important;
  }

  .mb_YTVPPlaypause {
    position: absolute;
    z-index: 2000;
    font-size: 70px;
    color: transparent;
    cursor: pointer;
    //margin-left: -20px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &:before {
      font-family: fontAwesome;
      display: inline-block;
      color: white;
      padding: 0 20px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, .3);

      .a-home & {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 98px;
          height: 98px;
          line-height: 97px;
          margin-left: -49px;
          margin-top: -49px;
      }
    }

    &.is-played {
      opacity: .1;
      font-size: 56px;

      &:hover {
        opacity: 1;
        @include single-transition(opacity, .25s, ease-in-out, 0s);
      }

      &:before {
        content: '\f04c';
        //padding: 10px 26px;
      }
    }

    &.is-paused {
      opacity: 1;

      &:before {
        content: '\f01d';
      }
    }

  }

  .mb_YTVPMuteUnmute,
  .mb_OnlyYT {
    position: absolute;
    bottom: 20px;
    z-index: 2000;
    font-size: 35px;
    width: 55px;
    height: 55px;
    line-height: 50px;
    opacity: 1;
    cursor: pointer;
    font-family: fontAwesome;
    color: white;
    background-color: rgba(0, 0, 0, .6);
    padding: 2px 10px;
    border-radius: 50%;
  }
  .mb_YTVPMuteUnmute {
    right: 30px;

    &.with-volume {
      &:before {
        content: '\f028';
      }
    }

    &.without-volume {
      &:before {
        content: '\f026';
        width: 100%;
      }
    }
  }

  .mb_OnlyYT {
    right: 100px;

    &:before {
      content: '\f065';
    }
  }

  .mb_YTVPProgress {
    position: absolute;
    bottom: 43px;
    right: 170px;
    background-color: rgba(0, 0, 0, .6);
    height: 10px;
    width: 300px;
    z-index: 2000;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    @include single-transition(opacity, .25s, ease-in-out, 0s);

    .mb_YTVTime, .mb_YTVPLoaded {
      height: 10px;
    }
    .mb_YTVPLoaded {
      background-color: darken(#fff, 50%);
    }
    .mb_YTVTime {
      background-color: #fff;
    }
  }

}

.mbYTP_wrapper.fullscreen #mbYTP_bgndVideo {
  margin-top: 0 !important;
  height: 100% !important;
}

.big-background-title {
  font-size: 78px;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 22px;
  padding-top: 20px;
  display: inline-block;
  background-attachment: scroll;
  background-repeat: repeat-x;
  background-position: top center;
}

a.big-background-btn {
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  background: transparent;
  border: 2px solid #fff;
  padding: 10px 14px;
  cursor: pointer;
  letter-spacing: 2px;
  text-align: center;
  display: inline-block;

  -webkit-transition: .4s background ease;
  -moz-transition: .4s background ease;
  -o-transition: .4s background ease;
  transition: .4s background ease;
}

.big-background-btn:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.20);
}

.big-background-default-image {
  width: 100%;
  height: 500px;
  z-index: 0;
  backface-visibility: hidden;
}

/* ABOUT SECTION
================================================== */
.about-section {
  font-family: 'Lato', sans-serif;
  color: #7f8c8d;
  background: #fff;
  padding: 60px 20px;
}

.about-section-container {
  text-align: center;
  padding-bottom: 50px;
}

.about-section-title {
  font-family: 'Raleway', sans-serif;
  font-size: 40px;
  background: #fff;
  color: #3d566e;
  padding: 0 35px;
  margin-bottom: 22px;
  background-attachment: scroll;
  background-repeat: repeat-x;
  background-position: center center;
  text-transform: uppercase;
}

a.about-section-btn {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
  color: #34495e;
  background: transparent;
  border: 2px solid #34495e;
  padding: 10px 14px;
  cursor: pointer;
  letter-spacing: 2px;
  text-align: center;
  display: inline-block;
  -webkit-transition: .4s background ease;
  -moz-transition: .4s background ease;
  -o-transition: .4s background ease;
  transition: .4s background ease;
}

.about-section-btn:hover {
  color: #fff;
  background: #34495e;
}

/* SMALL VIDEO BACKGROUND
================================================== */
.small-background-section {
  padding: 100px 0;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.small-background-container {
  position: relative;
  text-align: center;
}

.small-background-title {
  font-size: 40px;
  color: #f1c40f;
  font-weight: 300;
  z-index: 10;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
  background-attachment: scroll;
  background-repeat: repeat-x;
  background-position: top center;
}

/* MEDIA QUERIES
================================================== */

@media screen and (max-width: 768px) {
  .about-section-title {
    line-height: 1;
  }
  .big-background-default-image,
  .big-background {
    height: 200px;
    min-height: inherit;
  }
}

@media screen and (max-width: 480px) {
  .big-background-title {
    font-size: 58px;
  }

  .small-background-title {
    line-height: 1;
  }

  /*.player{ display: none;}  -> If you want to remove the video bg on a specific viewport w/o plugin*/
}

@media screen and (max-width: 360px) {
  .big-background-title, #colorize {
    line-height: 1
  }
}

@media screen and (max-width: 320px) {
  .small-background-title {
    font-size: 30px
  }
}

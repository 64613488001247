
.hi-hero {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    min-height: 400px;
    height: 64vh;

    @media (max-width: 767px) {
        background-image: none !important;
    }
    
    .img-responsive {
        object-fit: cover;
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        display: inline-block;

        @media (min-width: 768px) {
            display: none;
        }
    }

    &__title {
        @media (min-width: 992px){
            max-width: 50%;
        }

        @media (max-width: 768px){
            font-size: 32px;
        }
    }

    &__content {
        color: white;
    }
}

.home-news,
.home-content {
    padding: 45px 0;

    @media (min-width: 992px){
        padding: 90px 0;
    }
}

.home-content {
    h1 {
        @media (max-width: 992px){
            font-size: 34px;
        }
    }
}

.home-spots {
    display: flex;
    flex-direction: column;

    @media (min-width: 992px){
        flex-direction: row;
    }

    &__item {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 400px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #ccc;
        color: white;
        margin: 0 15px;
        margin-bottom: 30px;

        @media (min-width: 992px){
            min-height: 600px;
            margin-bottom: 0;
        }

        .text {
            max-width: 80%;
        }
    }
}

.home-news {
    h2 {
        margin-bottom: 30px;

        @media (min-width: 992px){
            margin-bottom: 60px;
        }
    }

    .img-box {
        margin-bottom: 20px;
        background-color: rgba(240,240,240,8);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 135px;

        &:after {
            position: absolute;
            left: 0;
            bottom: -2.5px;
            content: '';
            width: 76px;
            height: 5px;
            background-color: #00A9E0;
            z-index: 5;
        }

        > a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 7;
        }
    }

    &__box {
        margin-bottom: 30px;

        @media (min-width: 992px){
            margin-bottom: 0;
        }

        .date {
            display: inline-block;
            color: #00A9E0;
            font-size: 14px;
            font-weight: bold;
            line-height: 17px;
            margin-bottom: 10px;
        }

        h4 {
            margin-top: 0;
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        p {
            margin-bottom: 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .text-part {
            margin-bottom: 15px;

            @media (min-width: 992px){
                min-height: 130px;
            }
        }

        .btn--link {
            color: $primary-color!important;
        }
    }
    footer {
        text-align: right;

        .btn--link {
            color: $primary-color;
        }
    }
}

.single-image {
    background-color: $grey-light;
    min-height: 200px;

    .add-menu-sticky & {
        margin-top: 75px;

        @media (min-width: 767px) {
            margin-top: 80px;
        }

        @media (max-width: 767px) {
            margin-top: 0;
        }
    }
}

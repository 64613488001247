//Variables
@import "base/variables-trick";
@import "../network/base/mq";
@import "base/variables";
@import "helpers/mixins";

//Bootstrap
@import "vendors/bootstrap-custom";
@import "../network/bootstrap/variables";
@import "../network/bootstrap/mixins";
@import "../network/bootstrap";

//Base
@import "base/markup-mixins";
@import "base/pimcore";
@import "base/mediaqueries";
@import "base/fonts";
@import "base/base";

// Parts
@import "parts/header";
@import "parts/content";
@import "parts/footer";
@import "parts/sidebar";

// Modules
@import "modules/primary-menu";
@import "modules/carousel";
@import "modules/single-image";
@import "modules/card";
@import "modules/forms";
@import "modules/video-bg";
@import "modules/mobile-video";
@import "modules/cookielaw";
@import "modules/inf";
@import "modules/social";
@import "modules/images";
@import "modules/calculator";
@import "modules/navbar";
@import "modules/maps";
@import "modules/mat";
@import "modules/tooltip";
@import "modules/menu";
@import "modules/content";
@import "modules/nav-prod";
@import "modules/spots";
@import "modules/form";
@import "modules/buttons";
@import "modules/faq";
@import "modules/loading";
@import "modules/section-visual";
@import "modules/headings.scss";
@import "modules/products-grid";
@import "modules/figure";
@import "modules/extera-form";
@import "modules/animazione-prodotto";
@import "modules/zoom";
@import "modules/popup-banner";
@import "modules/win-fullscreen";
@import "modules/filter-banner";
@import "modules/video-html5";

//Pages
@import "pages/home";
@import "pages/landing";
@import "pages/fairs";
@import "pages/network-sites";
@import "pages/case-histories";
@import "pages/product";
@import "pages/piazza-prodotti";
@import "pages/scheda-prodotto";
@import "pages/search";
@import "pages/contact";
@import "pages/press-review";
@import "pages/press-release";
@import "pages/curriculum-posizioni-aperte";

// Custom
@import "helpers/extera";

// Vendors
@import "vendors/awesomeplete";
@import "vendors/flag-icon-sass";

// Custom
@import "helpers/custom";

.new-menu {
    float: right;
    
    > ul {
        margin-bottom: 0;
        padding-left: 0;

        > li {
            display: inline-block;
            text-transform: capitalize;
            margin: 0;

            &:last-child{
                a{
                    padding-right: 0;
                }
            }

            &:hover {
                .new-menu__sub {
                    display: flex;
                }
            }

            > a  {
                font-size: 18px;
                font-weight: 400;
                color: $primary-color;
                display: inline-block;
                padding: 32px 10px;
    
                transition: opacity .25s ease-in-out 0s;
                -webkit-transition: opacity .25s ease-in-out 0s;

                @media screen and (min-width: 992px){
                    padding: 32px 18px;
                }

                &[href*="contacts"] {
                    @extend .btn;
                    @extend .btn--rounded;
                    font-size: 1.4rem!important;
                    padding-top: 10px!important;
                    padding-bottom: 10px!important;
                    padding-left: 2rem!important;
                    padding-right: 2rem!important;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        height: 50px;
                        z-index: 4;
                    }
                }
    
                &:hover {
                    text-decoration: underline; 
                }
            }
        }
    }

    &__sub {
        display: none; 
        position: absolute;
        top: 81px;
        left: 0;
        width: 100%;
        z-index: 10;
        padding: 42px 16px;
        justify-content: center;
        background-color: $primary-color; 

        &:before,
        &:after{
            content: "";
            display: block;
            background-color: $primary-color;
            width: 100%;
            height: 100%;
            top: 0px;
            position: absolute;
        }

        &:before{
            left: -100%;
        }

        &:after{
            right: -100%;
        }

        li {
            display: inline;
            text-transform: capitalize;
            margin: 0 16px;

            &.active{
                a{
                    color: white;
                    text-decoration: underline;
                }
            }
        }

        li > a {
            text-align: center;
            color: #ffffff; 
            font-weight: 100; 
            font-size: 16px;
            display: block;

            svg{
                height: 48px;
                fill: #ffffff;
                display: block;
                margin: 0 auto 10px auto;
                transition: all 0.3s;
            }

            &:hover{
                svg{
                    transform: scale(1.2);
                }
                text-decoration: none;
                //color: #0f9cd4;
            }
        }
    }
}
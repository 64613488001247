body.c-press-release.a-detail {
  section.press-release {
    margin-bottom: 30px;
    ul.list-download {
      padding-left: 0;
      margin-bottom: 20px;
      li {
        margin-left: 1em;
        line-height: 1.3em;
        margin-bottom: .4em;
        a {
          color: $base-text-color;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
// Ricerca con autocomplete ////
// Autocomplete /////
.awesomplete [hidden] {
    display: none;
}

.awesomplete .visually-hidden {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.awesomplete {
    display: inline-block;
    position: relative;
    width: 100%;

    .editmode & {
        display: none;
    }

    > input {
        display: block;
        width: 100%;
        padding-right: 24px;
    }

    > ul {
        position: absolute;
        left: 0;
        z-index: 30;
        min-width: 100%;
        box-sizing: border-box;
        list-style: none;
        padding: 0;
        margin: 0;
        margin: .2em 0 0;
        //background: hsla(0,0%,100%,.9);
        //background: linear-gradient(to bottom right, white, hsla(0,0%,100%,.8));
        background-color: white;
        border: 1px solid $secondary-color;
        //box-shadow: .05em .2em .6em rgba(0,0,0,.2);
        text-shadow: none;

        &:empty {
            display: none;
        }
    }

    > ul > li {
        position: relative;
        padding: .2em .5em;
        cursor: pointer;
        font-size: 14px;
        color: $default;

        &:hover {
            //background: hsl(200, 40%, 80%);
            color: $default;
        }
    }
}

@supports (transform: scale(0)) {
    .awesomplete > ul {
        transition: .3s cubic-bezier(.4,.2,.5,1.4);
        transform-origin: 1.43em -.43em;
    }

    .awesomplete > ul[hidden],
    .awesomplete > ul:empty {
        opacity: 0;
        transform: scale(0);
        display: block;
        transition-timing-function: ease;
    }
}

/* Pointer */

.awesomplete > ul > li[aria-selected="true"] {
    //background: hsl(205, 40%, 40%);
    color: $default;
    opacity: .8;
}

.awesomplete mark {
    background: transparent;
    font-weight: bold;
}

.awesomplete li:hover mark {
    //background: rgba($grey-light,.8);
}

.awesomplete li[aria-selected="true"] mark {
    //background: hsl(86, 100%, 21%);
    color: inherit;
}
